<template>
  <div class="col-content">
    <vue-markdown :source="markdownContent" />
  </div>
</template>

<style scoped>
.col-content{
    line-height: 30px;
    padding: 20px; 
    text-align: left;
}
</style>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  components: {
    VueMarkdown,
  },
  data() {
    return {
      markdownContent: `# 启鹏AI个人信息保护规则
\n更新时间：2024-05-01
\n欢迎您使用启鹏AI！启鹏AI是由深圳市盐田区启鹏企划工作室（以下简称“启鹏”，“我们”）依托百度文心大模型的技术推出的生成类人工智能问答类技术服务（以下简称“本服务”）。我们非常重视您的隐私和个人信息保护。本个人信息保护规则适用于您通过任何方式对启鹏AI各项服务的访问和使用。
\n我们尽量以更清晰、更容易被您理解的方式展现本个人信息保护规则，以真实传达我们希望向您传达的信息，并希望您在向我们提供某些信息（其中很可能包括您的个人信息）以及允许我们处理并分享某些信息之前，能够清晰地了解这些信息收集的目的、可能的用途以及其他方面的内容。为了便于您阅读及理解，我们将专门术语进行了定义，您可以参见本个人信息保护规则“附录1：定义”来了解这些定义的具体内容。
\n本个人信息保护规则旨在帮助您了解我们会收集哪些个人信息、为什么收集这些个人信息，会利用这些个人信息做些什么及如何保护这些个人信息。请您务必认真阅读本个人信息保护规则，在确认充分了解本个人信息保护规则后使用启鹏AI服务。如果您或您的监护人不同意本个人信息保护规则的任何内容，您应该立即停止使用。在将您的信息用于本个人信息保护规则未涵盖的用途时，我们会事先征求您的同意。
\n《启鹏隐私政策总则》适用于启鹏集团所有产品和服务，如有本个人信息保护规则未尽事宜，以《启鹏隐私政策总则》为准，若本个人信息保护规则和《启鹏隐私政策总则》存在不一致，则优先适用本个人信息保护规则。本个人信息保护规则不适用于第三方提供的产品和服务，您在使用第三方提供的产品和服务时，应当阅读该等第三方发布的隐私政策或类似法律文件。
\n您可以在启鹏AI的相关服务功能中找到一些控制项，通过这些控制项，您可以对自己的信息进行动态管理。
\n请您确保您向我们提供的个人信息，以及您授权我们在本个人信息保护规则所述范围内收集、处理、使用、存储的相关个人信息，不会侵犯他人合法权益。
\n本个人信息保护规则将帮助您了解以下内容：
\n一、我们如何收集和使用您的个人信息
\n二、我们如何使用Cookie和同类技术
\n三、我们如何保存及保护您的个人信息
\n四、您的选择与权利
\n五、我们如何处理未成年人的个人信息
\n六、个人信息保护规则的更新
\n七、如何联系我们
\n我们深知个人信息对您的重要性，也深知为您的信息提供有效保护是我们业务健康可持续发展的基石。感谢您对启鹏AI的使用和信任！我们致力于维持您对我们的信任，恪守适用法律和我们对您的承诺，尽全力保证您的个人信息安全和合理使用。同时，我们郑重承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
\n## 一、 我们如何收集和使用您的个人信息
\n我们会出于本个人信息保护规则所述的以下目的，收集和使用您的个人信息。我们理解您输入的对话信息、提交的信息反馈以及其他您向我们提供的信息中可能包含他人的个人信息，这种情况下请您务必在提供前取得他人的合法授权，避免造成他人个人信息的不当泄露。
\n1. 注册/登录
\n当您申请使用启鹏AI服务，可以登录已经注册的启鹏通用账号。当注册启鹏通用账号时，您需向我们提供以下信息：账号名称、密保邮箱、密保手机、密保问题，并创建账号密码。有关启鹏通用账号的创建和登录可进一步参照《启鹏隐私政策总则》
\n我们可能会通过您提供的手机号码、邮箱向您发送有关启鹏AI产品和服务的最新讯息。如果您不希望收到该讯息，可以根据讯息中提供的方式拒绝接收此类信息或者通过【service@qipengai.com】联系我们进行处理。
\n2. 人机交互对话
\n当您成功连接我们的服务后，您可以通过对话框与我们的启鹏AI进行交互对话。我们会自动接收并记录您与启鹏AI进行交互过程中自愿输入的对话信息，使用这些信息向您输出内容，以提供人机交互服务。在对话过程中，您还可以对启鹏AI所输出的内容进行评价，包括点赞、踩等。我们将收集您的评价信息，包括您主动提交的报错原因，以便不断改进启鹏AI的输出内容质量。
\n如您拒绝我们收集和处理前述个人信息，请您谨慎输入前述信息，但因此您可能会影响您正常使用启鹏AI提供的部分或全部功能。
\n3. 问答历史
\n为向您提供连续性、一致化的使用体验，保障服务质量，我们会记录您的个人对话记录，包括您输入的对话信息、以及基于上述信息形成的对话主题。您可以查找并管理您尚未删除的问答历史。请您知悉，为符合相关法律法规要求，履行网络信息安全义务，我们会在法律允许的时间内保留您的问答历史及对话主题。
\n4. 客服功能
\n您在联系我们时，我们会要求您提供并使用您的手机号码、启鹏通用账号信息。为保证您的账号安全，我们提供客户服务时会使用您的手机号码、启鹏通用账号信息，以核验您的身份。当您需要我们提供与您使用过程信息相关的客服服务时，我们将会查询您的使用信息。如您拒绝提供，则无法使用客服功能。我们还会收集和使用您在使用客服功能过程中自愿提供的其他信息，以响应您的服务请求。
\n5. 付费服务功能
\n当您在启鹏AI购买商品或服务时，我们或提供收银服务的第三方会生成您的订单信息。为便于您了解、查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的交易信息（即购买商品名称、下单时间、付款金额）用于向您展示及便于您对订单进行管理。您可以通过【我的】-【订单】查看和管理您的订单信息。
\n您可以通过与我们合作的第三方支付机构进行订单支付（包括支付宝(中国)网络技术有限公司、深圳市腾讯计算机系统有限公司）。第三方支付机构为实现您的支付功能需要收集您的【设备信息(如IMEI/设备唯一标识符/设备 MAC 地址-SID)、网络类型】，这些信息是支付功能所必需的信息，拒绝提供将导致你无法使用该付费功能，但不影响其他功能的正常使用。
\n如果您需要提出退款申诉，您需要向我们提供【身份信息、订单信息及其他必需的相关信息】，以便进行订单情况查询以跟进处理您的退款申诉。
\n6. 为您提供安全保障
\n为提高您使用我们服务的安全性，我们可能使用您的密保邮箱地址、密保手机号码、使用记录信息用于身份验证、客户服务、安全防范、诈骗监测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、其他使用本服务的用户、我们及社会公众的合法权益。
\n7. 其他用途
\n1.我们将根据相关法律法规的要求通过技术手段对个人信息进行必要的去标识化或匿名化处理，处理后的信息将无法精确识别到特定个人信息主体。请您了解并同意，在不透露您个人信息且不违背相关法律法规的前提下，我们有权对用户数据进行分析并予以利用，包括但不限于使用技术处理后的对话信息提高启鹏AI对您输入内容的理解能力，以便不断改进启鹏AI的识别和响应的速度和质量，提高启鹏AI的智能性。
\n2.当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理、去标识化等必要方式对您的信息进行脱敏展示，以保护您的信息安全。
\n3.当我们要将您的个人信息用于本个人信息保护规则未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。请您理解，我们向您提供的服务将不断更新变化。如果您选择使用本个人信息保护规则中尚未列明的其他功能时，在我们收集您的个人信息前，我们会通过协议、页面提示的方式向您详细说明信息收集的目的、方式、范围并征求您的明确同意。若您不同意提供前述信息，您可能无法使用该项服务，但不影响您使用其他服务。
\n8. 事先征得授权同意的例外
\n请注意：在以下情形中，收集、使用个人信息无需事先征得您的授权同意：
\n1) 与国家安全、国防安全直接相关的；
\n2) 与公共安全、公共卫生、重大公共利益直接相关的；
\n3) 与刑事侦查、起诉、审判和判决执行等直接有关的；
\n4) 为履行法定职责或者法定义务所必需；
\n5) 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
\n6) 所收集的个人信息是您自行向社会公众公开的；
\n7) 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
\n8) 为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
\n9) 为订立、履行您作为一方当事人的合同所必需，或者根据您的要求向您提供服务所必需的；
\n10) 用于维护所提供的服务的安全稳定运行所必需的，例如发现、处置服务的故障；
\n11) 法律法规规定的其他情形。
\n## 二、 我们如何使用Cookie和同类技术
\n为确保网站正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。Cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用Cookie技术，我们能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。
\n当您使用本服务时，我们会向您的设备发送Cookie。当您与我们提供给合作伙伴的服务（例如广告和/或推广服务，以及可能显示在其他网站上的由启鹏提供的服务功能）进行交互时，我们允许Cookie（或者其他匿名标识符）将您访问、使用产品或服务的相关信息发送至启鹏的服务器。
\n我们不会将Cookie用于本个人信息保护规则所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改使用人员设置，但您可能因为该等修改，无法登录或使用依赖于Cookie的启鹏提供的服务或功能。
\n## 三、 我们如何保存及保护您的个人信息
\n1. 保存期限
\n我们将您在使用启鹏AI服务期间为您保存您的个人信息。如果您注销账号或主动删除上述信息，我们将在《个人信息保护法》、《网络安全法》、《数据安全法》等法律法规允许范围内保存您的信息。
\n2. 保存地域
\n您的个人信息均储存于中华人民共和国境内，我们不会向境外任何第三方提供。
\n3. 安全措施
\n1.我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过相关协议和个人信息保护规则告知您相关信息的使用目的和范围。
\n2.我们非常重视信息安全。我们成立了专责团队负责研发和应用多种安全技术和程序等，我们会对安全管理负责人和关键安全岗位的人员进行安全背景审查，我们建立了完善的信息安全管理制度和内部安全事件处置机制等。我们会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止您的信息丢失、遭到被未经授权的访问、公开披露、使用、毁损、丢失或泄漏。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
\n3.我们会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。我们会对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，则会追究其相关法律责任。
\n4.我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的服务时所用的系统和通讯网络，均有可能在我们控制之外的其他环节出现安全问题。
\n5.根据我们的安全管理制度，个人信息泄露、毁损或丢失事件被列为公司级特大安全事件，一经发生将启动公司最高级别的紧急预案，由安全部、政府关系部、法务部等多个部门组成联合应急响应小组处理。
\n4. 安全事件通知
\n1.我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。
\n2.个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，组成紧急应急小组，在最短时间内追溯原因并减少损失。
\n3.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
\n## 四、 您的选择与权利
\n按照中国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利：
\n1. 查阅、复制、转移您的个人信息
\n如果您希望查阅或复制您的账号的绑定信息、更改您的密码、管理安全设备信息、进行账号关联、身份认证等，您可以通过服务【账号与安全】执行此类操作。
\n如果您希望查阅或复制您账号的问答历史，您可以在服务页面左侧查看对话历史记录以执行此类操作，并可以搜索指定的历史记录。
\n如果您希望查阅或复制您的订单信息，您可以通过【我的】-【订单】执行此类操作。
\n如果您无法通过上述链接查阅或复制该等个人信息或者您希望查阅、复制您在使用我们的服务过程中产生的其他个人信息，您可以通过【service@qipengai.com】随时与我们联系。
\n如果您需要转移我们收集的个人信息，您可以通过【service@qipengai.com】随时与我们联系，我们将在符合国家相关法律法规及网信部门规定的条件下按照相关规定的要求提供转移的途径。
\n2. 更正、补充您的个人信息
\n您发现我们处理的关于您的个人信息有错误或不完整时，您有权对错误或不完整的信息作出更正或补充，您可以通过【service@qipengai.com】随时与我们联系。为保障安全，我们将在您行使更正、补充权前对您的身份进行验证。
\n3. 删除您的个人信息
\n如果您希望删除您账号的问答历史，您可以通过服务页面左侧查看对话历史记录，并执行删除操作。
\n在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过【service@qipengai.com】随时与我们联系：
\n1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；
\n2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。
\n当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
\n4. 改变您授权同意的范围
\n如您想改变授权范围，您可通过启鹏平台产品的隐私设置修改授权范围，例如：
\n您可以登录【账号与安全】界面解除启鹏账号与第三方账号的绑定关系。
\n当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
\n5. 注销您的账号
\n您随时可注销此前注册的账号。您可以登录【账号与安全】，注销您的启鹏账号。一旦您注销启鹏账号，将无法使用启鹏全线用户产品和服务，因此请您谨慎操作。为了保护您或他人的合法权益，我们会结合您对启鹏各产品和服务的使用情况判断是否支持您的注销请求。如您通过第三方账号授权登录启鹏时，需要向第三方申请注销账号。
\n6. 提前获知服务停止运营
\n启鹏愿一直陪伴您，若因特殊原因导致启鹏AI或其他启鹏平台产品和服务被迫停止运营，我们将按照法律法规要求在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。
\n7. 响应您的上述请求
\n为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。通常我们会在15日内响应您的请求。
\n对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
\n在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：
\n1.与我们履行法律法规规定的义务相关的；
\n2.与国家安全、国防安全直接相关的；
\n3.与公共安全、公共卫生、重大公共利益直接相关的；
\n4.与刑事侦查、起诉、审判和执行判决等直接相关的；
\n5.我们有充分证据表明您存在主观恶意或滥用权利的（如您的请求将危害公共安全和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）；
\n6.响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；
\n7.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
\n8.涉及商业秘密的。
\n## 五、 我们如何处理未成年人的个人信息
\n启鹏非常重视对未成年人信息的保护。
\n我们的网站和服务主要面向成人，我们将不满18周岁的任何人均视为未成年人。我们将根据国家相关法律法规的规定保护未成年人的个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
\n如果我们发现自己在未事先获得必要的监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关个人信息。
\n若您是未成年人，建议您请您的监护人仔细阅读本个人信息保护规则，并在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。
\n如您的监护人不同意您按照本个人信息保护规则使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
\n如果监护人发现我们在未获监护人同意的情况下收集了未成年人的个人信息，请通过【service@qipengai.com】联系我们，我们会设法尽快删除相关个人信息。
\n## 六、 个人信息保护规则的更新
\n请您理解，我们可能适时修订本个人信息保护规则内容。对于会导致您在本个人信息保护规则项下权利的实质减损的重大变更，我们会于变更生效前在服务的主要曝光页面或通过站内信、电子邮件或其他合适的能触达您的方式通知您。若您不同意该等变更，您可以停止使用启鹏AI服务。
\n本个人信息保护规则所指的重大变更包括但不限于：
\n1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
\n2.我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
\n3.您参与个人信息处理方面的权利及其行使方式发生重大变化；
\n4.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
\n5.个人信息安全影响评估报告表明存在高风险时。
\n## 七、如何联系我们
\n如果您对启鹏AI及本个人信息保护规则有任何意见或建议，您可以通过发送邮件至【service@qipengai.com】联系我们。
\n为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求。一般情况下，我们将在15天内回复。
\n如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向北京市海淀区人民法院提起诉讼。
\n附录1：定义
\n本个人信息保护规则中使用的特定词语，具有如下含义：
\n1.“我们”或“启鹏”，指深圳市盐田区启鹏企划工作室。
\n2.“您”，指使用启鹏AI服务的参与人员。
\n3.“启鹏AI”，指启鹏依托百度文心大模型的技术推出的生成类人工智能问答类技术服务。
\n4.“启鹏平台”，指启鹏网站（https://www.qipengai.com）及启鹏客户端。
\n5.“个人信息”，指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于敏感个人信息。
\n6.“个人信息主体”，指个人信息所关联的自然人。
\n7.“敏感个人信息”，指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息。敏感个人信息包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满14周岁未成年人的个人信息。
\n8.“去标识化”，指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
\n9.“匿名化”，指个人信息经过处理无法识别特定自然人且不能复原的过程。
\n10.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本个人信息保护规则之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。
\n深圳市盐田区启鹏企划工作室
\n生效日期：2024年05月01日
`,
    };
  },
};
</script>
