<template>
    <div class="dBody">
        <br>
        <div class="box-form">
            <br>
            <br>
            <div class="left" v-if="!isMobile">
                <div class="overlay">
                    <h1>Hello AI.</h1>
                    <h2>-让工作更美好</h2>
                    <span>
                        <p>启鹏Ai，由启鹏供应链精心研发，专注于为用户提供高效、精准的智能文本服务。</p>
                        <p>作为您工作、学习、生活的智能伙伴，我能够运用先进的人工智能技术，快速响应您的需求，助力您实现更高效的信息处理与创作。</p>
                        <p>启鹏供应链，以技术为驱动，以创新为灵魂，致力于将前沿科技与实际应用紧密结合，为用户带来更加智能、便捷的生活体验。</p>
                    </span>
                </div>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
            </div>
            <div class="right">
                <h5>
                    <img alt="启鹏Ai logo" src="../../assets/logo.png">
                </h5>
                <div class="inputs">

                    <div class="layui-row layui-col-space15">
                        <div class="layui-col-sm12 layui-col-md12">
                            <!-- <div class="layui-col-xs6 layui-col-sm6 layui-col-md6"> -->
                                <div class="layui-form-item">
                                <input type="text" name="phone" v-model="phone" autocomplete="off" placeholder="手机号码"
                                    class="layui-input">
                                <i class="layui-icon layui-icon-username zyl_lofo_icon"></i>
                            <!-- </div> -->
                            </div>
                        </div>
                        <div class="layui-col-sm12 layui-col-md12">
                            <div class="layui-row">
                                <div class="layui-col-xs6 layui-col-sm6 layui-col-md6">
                                    <div class="layui-form-item">
                                        <input type="text" name="vercode" v-model="code" id="vercode" autocomplete="off"
                                            placeholder="验证码" class="layui-input" maxlength="6">
                                        <i class="layui-icon layui-icon-vercode zyl_lofo_icon"></i>
                                    </div>
                                </div>
                                <!-- <div class="layui-col-xs2 layui-col-sm2 layui-col-md2">&nbsp;</div> -->
                                <div class="layui-col-xs6 layui-col-sm6 layui-col-md6 demoTable" style="text-align: right;">
                                    <button id="getcode" @click="getcode" class="layui-btn layui-bg-gray"
                                        ref="getcode">获取验证码</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br><br>
                    <button class="layui-btn layui-bg-blue layui-btn-fluid" @click="toLogin">立即登录</button>
                    <br><br>
                    <div style="height: 30px; line-height: 30px; margin-top: 20px; margin-bottom: 20px;">
                        还没有启鹏Ai帐号？<el-link type="primary" @click="toRegister">立即注册</el-link></div>
                </div>
            </div>
        </div>

        <div class="foot">
            Copyright 2024 ©启鹏供应链 版权所有 备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"><u>粤ICP备2024240429号-1</u></a>
        </div>
    </div>
</template>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'


export default {
    name: 'Hello_AI',
    data() {
        return {
            LGOIN_CODE: 0,
            BIZID: "",
            vodeA: undefined,
            phone: "",
            code: "",
            isMobile: false,
        };
    },
    created() {

        const devT = this.getDeviceType();
        if (devT == 'mobile') {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }
        this.init();
    },
    methods: {
        jishi: function () {
            if (this.$data.LGOIN_CODE <= 0) {
                this.$data.BIZID = "";
                cookieH.remove("LOGIN_CODE");
                // cookieH.remove("LOGIN_BIZID");
                this.$refs.getcode.innerText = "获取验证码";
                this.$refs.getcode.removeAttribute("disabled");
                if (this.$data.vodeA != undefined) {
                    clearInterval(this.$data.vodeA);
                    this.$data.vodeA = undefined;
                }
            } else {
                this.$data.LGOIN_CODE--;
                cookieH.set("LOGIN_CODE", this.$data.LGOIN_CODE, { expires: 7 });
                this.$refs.getcode.innerText = this.$data.LGOIN_CODE + " s";
                this.$refs.getcode.setAttribute('disabled', 'disabled');
            }
        },
        checkLogin() {
            // console.log("checkLogin")
            const token = cookieH.get("userTK");
            // console.log("checkLogin - token",token)
            if (token != "" && token != undefined) {
                if (this.getDeviceType() == "mobile") {
                    this.$router.replace('/mobileChat/0');
                } else {
                    this.$router.replace('/chatMain/0/0');
                }
                return false;
            }
            return true;
        },
        init: function () {
            // console.log("jiancha")
            const ab = this.checkLogin();

            var codeCookie = cookieH.get("LOGIN_CODE");
            if (codeCookie == 0 || codeCookie == undefined) {
                this.$data.LGOIN_CODE = 0;

            } else {
                this.$data.LGOIN_CODE = codeCookie;
            }
            if (this.$data.vodeA == undefined && ab) {
                this.$data.vodeA = setInterval(this.jishi, 1000)
            }

        },
        getcode: async function () {
            try {
                this.validateCode();
                const loginUrl = $conf.dev.apiUrl + 'index/sms';
                const response = await axios.post(loginUrl, {
                    phone: this.$data.phone,
                    type: 1
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resD = response.data;
                if (resD.code == 0) {
                    this.$message.info(resD.msg);
                    cookieH.set("LOGIN_CODE", 60, { expires: 1 });
                    cookieH.set("LOGIN_BIZID", resD.data, { expires: 1 });
                    this.$data.BIZID = resD.data;
                    this.init();
                } else {
                    this.$message.error(resD.msg);
                }
            } catch (error) {
                this.$message.error(error);
            }
        },
        toLogin: async function () {
            try {
                this.validate();
                const loginUrl = $conf.dev.apiUrl + 'index/login';
                const response = await axios.post(loginUrl, {
                    phone: this.$data.phone,
                    code: this.$data.code,
                    bizid: this.$data.BIZID
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const resD = response.data;
                if (resD.code == 0) {
                    cookieH.set("userTK", resD.data, { expires: 7 });
                    cookieH.remove("LOGIN_CODE");
                    cookieH.remove("LOGIN_BIZID");
                    clearInterval(this.$data.vodeA);
                    if (this.getDeviceType() == "mobile") {
                        this.$router.replace('/mobileChat/0');
                    } else {
                        this.$router.replace('/chatMain/0/0');
                    }
                } else {
                    this.$message.error(resD.msg);
                }
            } catch (error) {
                this.$message.error(error);
            }
        },
        toRegister: function () {
            this.$router.push('/veReg');
        },
        validateCode: function () {
            if (this.phone.trim() == "") {
                this.$message.error("手机号码必填");
                return false;
            }
            const phontRe = /^1[3-9]\d{9}$/;
            if (!phontRe.test(this.phone)) {
                this.$message.error("手机号码格式错误");
                return false;
            }
        },
        validate: function () {
            if (this.$data.phone == "") {
                this.$message.error("手机号码必填");
                return false;
            }
            const phontRe = /^1[3-9]\d{9}$/;
            if (!phontRe.test(this.$data.phone)) {
                this.$message.error("手机号码格式错误");
                return false;
            }
            if (this.$data.code == "") {
                this.$message.error("验证码必填");
                return false;
            }
        }, getDeviceType() {
            let isMobile = false;
            // 首先检查navigator.maxTouchPoints，这是一个更可靠的方法  
            console.log(navigator.maxTouchPoints)
            if ('maxTouchPoints' in navigator) {
                isMobile = navigator.maxTouchPoints > 0 ? true : false;
                return isMobile ? 'mobile' : 'desktop';
            }
            else {

                // 常见的移动设备用户代理片段  
                const mobileUserAgentPatterns = [
                    /Android/i,
                    /webOS/i,
                    /iPhone|iPad|iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i,
                    /Opera Mini/i,
                    /IEMobile/i,
                    /Mobile/i, // 通用移动标识  
                    /Mobile Safari/i,
                    /Opera Mobi/i,
                    /Tablet/i, // 平板电脑通常也作为移动设备处理  
                    /Mobile MSIE/i,
                    /Windows CE/i,
                    /UCWEB/i,
                    /UC Browser/i,
                    /MiuiBrowser/i,
                    /Mobile Safari/i,
                    /Chrome Mobile/i,
                    /Firefox Mobile/i
                ];

                // 获取用户代理字符串  
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                console.log(userAgent)
                // 检查用户代理是否包含上述任何移动设备标识  
                for (let i = 0; i < mobileUserAgentPatterns.length; i++) {
                    if (userAgent.match(mobileUserAgentPatterns[i])) {
                        return 'mobile'; // 是移动设备  
                    }
                }
                return 'desktop';
            }

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dBody {
    height: 100vh;
    /* overflow: hidden; */
    background-image: linear-gradient(135deg, #FAB2FF 10%, #1904E5 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: "Open Sans", sans-serif;
    color: #333333;
}

.box-form {
    margin: 0 auto;
    /* margin-top: 10px; */
    width: 80%;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex: 1 1 100%;
    align-items: stretch;
    justify-content: space-between;
    box-shadow: 0 0 20px 6px #090b6f85;
}

@media (max-width: 980px) {
    .box-form {
        flex-flow: wrap;
        text-align: center;
        align-content: center;
        align-items: center;
    }
}

.box-form div {
    height: auto;
}

.box-form .left {
    color: #FFFFFF;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/left-bg.jpg");
    overflow: hidden;
}

.box-form .left .overlay {
    padding: 30px;
    width: 100%;
    height: 100%;
    background: #5961f9ad;
    overflow: hidden;
    box-sizing: border-box;
}

.box-form .left .overlay h1 {
    font-size: 9vmax;
    line-height: 1;
    text-align: left;
    font-weight: 900;
    margin-top: 40px;
    margin-bottom: 20px;
}

.box-form .left .overlay h2 {
    font-size: 5vmax;
    line-height: 1;
    text-align: left;
    font-weight: 900;
    margin-top: 40px;
    margin-bottom: 20px;
}

.box-form .left .overlay span {
    display: block;
    padding-top: 50px;
}

.box-form .left .overlay span p {
    /* font-weight: 900; */
    text-indent: 2em;
    text-align: left;
    line-height: 200%;
}

.box-form .left .overlay span a {
    background: #3b5998;
    color: #FFFFFF;
    margin-top: 10px;
    padding: 14px 50px;
    border-radius: 100px;
    display: inline-block;
    box-shadow: 0 3px 6px 1px #042d4657;
}

.box-form .left .overlay span a:last-child {
    background: #1dcaff;
    margin-left: 30px;
}

.box-form .right {
    padding: 40px;
    overflow: hidden;
}

@media (max-width: 980px) {
    .box-form .right {
        width: 100%;
    }
}

.box-form .right h5 {
    font-size: 6vmax;
    line-height: 1;
}

.box-form .right h5 img {
    width: 200px;
    margin-top: 40px;
}

.box-form .right p {
    font-size: 14px;
    color: #B0B3B9;
}

.zyl_lofo_icon {
    position: absolute;
    left: 5px;
    top: 8px;
    font-size: 20px;
    /* color:#FFF; */
    color: #000000;
}

.box-form .right input {
    padding-left: 30px;
}

.box-form .right input::placeholder {
    color: #bbb9b9;
}

.box-form .right .inputs {
    margin-top: 50px;
    /* padding: 0px 40px 0px 40px; */
}

.foot {
    position: absolute;
    /* margin-top: 30%; */
    color: #FFF;
    height: 40px;
    line-height: 40px;
    text-align: center;
    bottom: 0px;
    left: 50%;
    /* 移动到父元素宽度的50%位置 */
    transform: translateX(-50%);
    /* 向左移动自身宽度的50%，以实现居中 */
    width: auto;
    /* 宽度根据内容自动调整 */
}

.foot a {
    color: #FFF;
}

/* 
.box-form .right .inputs {
    overflow: hidden;
}

.box-form .right input {
    width: 100%;
    padding: 10px;
    margin-top: 25px;
    font-size: 16px;
    border: none;
    outline: none;
    border-bottom: 2px solid #B0B3B9;
}

.box-form .right .remember-me--forget-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-form .right .remember-me--forget-password input {
    margin: 0;
    margin-right: 7px;
    width: auto;
}

.box-form .right button {
    float: right;
    color: #fff;
    font-size: 16px;
    padding: 12px 35px;
    border-radius: 50px;
    display: inline-block;
    border: 0;
    outline: 0;
    box-shadow: 0px 4px 20px 0px #49c628a6;
    background-image: linear-gradient(135deg, #70F570 10%, #49C628 100%);
}

label {
    display: block;
    position: relative;
    margin-left: 30px;
}

label::before {
    content: " ";
    position: absolute;
    font-family: FontAwesome;
    background: transparent;
    border: 3px solid #70F570;
    border-radius: 4px;
    color: transparent;
    left: -30px;
    transition: all 0.2s linear;
}

label:hover::before {
    font-family: FontAwesome;
    content: " ";
    color: #fff;
    cursor: pointer;
    background: #70F570;
}

label:hover::before .text-checkbox {
    background: #70F570;
}

label span.text-checkbox {
    display: inline-block;
    height: auto;
    position: relative;
    cursor: pointer;
    transition: all 0.2s linear;
}

label input[type=checkbox] {
    display: none;
} */
</style>