<template>
    <div>
        <el-row>
            <el-col :span="24">
               <div class="title">套餐管理</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                订单编号：
                                <el-input
                                    type="text"
                                    placeholder="输入订单编号"
                                    v-model="query.tradeNo"
                                    style="width: 200px !important;">
                                </el-input>
                                &nbsp;
                                订单状态：
                                <el-radio-group v-model="query.tradeStatus">
                                    <el-radio :label="-1">全部</el-radio>
                                    <el-radio :label="0">未支付</el-radio>
                                    <el-radio :label="1">支付成功</el-radio>
                                    <el-radio :label="2">支付失败</el-radio>
                                </el-radio-group>
                                &nbsp;
                                推广人码：
                                <el-input
                                    type="text"
                                    placeholder="输入推广人的推广码"
                                    v-model="query.father"
                                    maxlength="11"
                                    style="width: 150px !important;">
                                </el-input>
                                <el-button type="success" @click="initorderList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <!-- <el-button type="primary" @click="toAdd">添加套餐</el-button> -->
                        </div>
                    </el-col>
                </el-row>
                <el-table
                    ref="OrderTable"
                    :data="orderList.data"
                    tooltip-effect="dark"
                    border
                    stripe
                    :summary-method="getSummaries"
                    show-summary
                    height="600">
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="id"
                        label="订单ID"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="tradeNo"
                        label="订单编号"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        label="订单金额"
                        sortable
                        prop="orderMoney">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        sortable
                        label="订单日期">
                    </el-table-column>
                    <el-table-column
                        prop="tradeTime"
                        sortable
                        label="支付日期">
                    </el-table-column>
                    <el-table-column
                        prop="tradeType"
                        sortable
                        label="支付方式">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.tradeType === '支付宝' ? 'primary' : 'success'"
                            disable-transitions>{{scope.row.tradeType}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="tradeStatus"
                        sortable
                        label="支付状态">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.tradeStatus === '成功' ? 'success' : 'info'"
                            disable-transitions>{{scope.row.tradeStatus}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="nickName"
                        sortable
                        label="用户昵称"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="tcName"
                        sortable
                        label="套餐名称"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="tcLV"
                        label="提成点数"
                        show-overflow-tooltip>
                        <template slot-scope="scope">{{ scope.row.tcLV*100 }}%</template>
                    </el-table-column>
                    <el-table-column
                        prop="tcMoney"
                        label="可提成金额"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="tcStatus"
                        sortable
                        label="提成状态"
                        show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.tcStatus === 0 ? 'success' : 'danger'"
                            disable-transitions>{{scope.row.tcStatus===0?'未锁定结算':'锁定结算中'}}</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="orderList.count"
                    :page-size="pageSize"
                    @current-change="initorderList">
                </el-pagination>
            </el-col>
        </el-row>
        
        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogpackAdd" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="5">&nbsp;</el-col>
                <el-col :span="14">
                    <el-card>
                        <el-form ref="packForm" :model="form" label-width="80px">
                            <el-form-item label="套餐名称">
                                <el-input v-model="form.tcName"></el-input>
                            </el-form-item>
                            <el-form-item label="原价">
                                <el-input type="number" v-model="form.amount"></el-input>
                            </el-form-item>
                            <el-form-item label="优惠价">
                                <el-input type="number" v-model="form.amountYH"></el-input>
                            </el-form-item>
                            <el-form-item label="有效期">
                                <el-input type="number" v-model="form.endDays"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" style="text-align:left;">
                                <el-radio-group v-model="form.status">
                                    <el-radio :label="1" border>有效</el-radio>
                                    <el-radio :label="0" border>无效</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item style="text-align: center;">
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="5"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>
.tbHeader,.tbHeader th{
    background-color: #fafafa !important;
    color:#333;
    font-weight: 500;
}
.title{
    font-size: 20px;
    text-align: left; 
    /* text-indent: 2em; 
    background: #f5f7fa75;  */
    height: 30px; 
    line-height: 30px;
    margin-bottom: 20px;
}
.query{
    margin-bottom: 20px; 
    text-align: left;
}
/* .query .el-input{
    width:150px ;
} */
.query .content .el-button{
    margin-left:10px;
}
</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'taocanManage',
        data() {
            return {
                dialogpackAdd:false,
                isShowIdColumn:false,
                query:{
                    tradeNo:'',
                    tradeStatus:-1,
                    father:''
                },
                qiuhe:[
                    "orderMoney",
                    "tcMoney",
                ],
                pageSize:10,
                orderList:[],

                form:{
                    id:0,
                    tcName:'',
                    amount:0,
                    amountYH:0,
                    status:1,
                    endDays:0,
                    title:''
                },
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initorderList(1)
            },
            closeDialog(){
                this.form.id=0;
                this.form.tcName=''
                this.form.status=1
                this.form.amount=0
                this.form.amountYH=0
                this.form.endDays=0
                this.form.title=''
                this.dialogpackAdd=false
            },
            greCurrentStamp(){
                let timestamp = Date.now();
                return timestamp;
            },
            async initorderList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'admin/order/list';
                    const response = await axios.post(loginUrl, {
                        tradeNo:this.query.tradeNo,
                        tradeStatus:this.query.tradeStatus,
                        father:this.query.father,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(order => {
                            order.createTime = $conf.common.formattedDate(order.createTime);
                            order.tradeTime = $conf.common.formattedDate(order.tradeTime);
                            order.tradeType=order.tradeType==1?'微信':order.tradeType==2?'支付宝':'未知';
                            order.tradeStatus=order.tradeStatus==1?'成功':order.tradeStatus==2?'失败':'未支付';
                            order.isSelect = order.tcStatus==0;
                        });
                        this.orderList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            getSummaries(param){
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (this.qiuhe.includes(column.property)) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] = sums[index].toFixed(2) + ' 元';
                    } else {
                        sums[index] = '';
                    }
                });

                return sums;
            },
            handleSelectionChange(){

                
            },
            handleDateChange(value){
                this.form.expressTime = $conf.common.formatTimeStamp(value)/1000
            },
            toAdd(){
                this.dialogpackAdd=true
                this.form.id=0;
                this.form.tcName=''
                this.form.status=1
                this.form.amount=0
                this.form.amountYH=0
                this.form.endDays=0
                this.form.title="添加套餐"
            },
            handleClick_Edit(row){
                this.dialogpackAdd=true
                this.form.title="编辑套餐"
                this.form.id=row.id;
                this.form.tcName=row.tcName
                this.form.status=row.status
                this.form.amount=row.amount
                this.form.amountYH=row.amountYH
                this.form.endDays=row.endDays
                console.log(row);

            },
            async handleClick_Delete(row){
                let vm = this
                const h = this.$createElement;
                this.$msgbox({
                    title:'确认信息',
                    message:h('p', null, [
                        h('span', null, '注意：系统'),
                        h('b', { style: 'color: teal' }, '不建议'),
                        h('span', null, '删除套餐！可以选择关闭此套餐；如果确定执意要删除，请选择[执意删除]，删除后不可恢复！ ')
                    ]),
                    distinguishCancelAndClose: true,
                    showCancelButton:true,
                    confirmButtonText: '设置关闭',
                    cancelButtonText: '执意删除'
                })
                .then(() => {
                    vm.form.id=row.id;
                    vm.form.tcName=row.tcName
                    vm.form.status=0
                    vm.form.amount=row.amount
                    vm.form.amountYH=row.amountYH
                    vm.form.endDays=row.endDays
                    vm.onSubmit();
                })
                .catch(async action => {
                    if(action === 'cancel'){
                        try {
                            const loginUrl=$conf.dev.apiUrl+'admin/pack/delete';
                            const response = await axios.post(loginUrl, {
                                id:row.id,
                            },{
                                headers: {
                                    "Content-Type": "application/json"
                                }
                            });  
                            const resD = response.data;
                            if(resD.code==0){
                                this.$message.success({
                                    message:resD.data,
                                    duration:1000,
                                    onClose:function(){
                                        vm.initorderList(1)
                                        vm.closeDialog()
                                    }
                                })
                            }else{
                                this.$message.error(resD.msg);  
                            }
                        } catch (error) {  
                            this.$message.error(error);  
                        } 
                    }
                });
            },
            async onSubmit(){
                console.log(this.form)
                let vm = this
                try {
                    const loginUrl=$conf.dev.apiUrl+'admin/pack/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        tcName:this.form.tcName,
                        status:this.form.status,
                        amount:this.form.amount,
                        amountYH:this.form.amountYH,
                        endDays:this.form.endDays,
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.data,
                            duration:1000,
                            onClose:function(){
                                vm.initorderList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>