import Vue from 'vue'
import router from './router' // 引入路由配置 
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'layui/dist/css/layui.css'
import './assets/own.css'
import './assets/fontF.css'
// import index from './components/index/index.vue'
// import chat from './components/chat/pi.vue'
import App from './App.vue'


Vue.config.productionTip = false

Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

