<template>
    <div>
        <el-row>
            <el-col :span="24">
               <div class="title">用户管理</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                账户：
                                <el-input
                                    type="text"
                                    placeholder="输入手机号"
                                    v-model="query.userName"
                                    maxlength="11"
                                    style="width: 150px !important;">
                                </el-input>
                                <el-button type="success" @click="initUserList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <el-button type="primary" @click="toAdd">添加用户</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-table
                    ref="table"
                    :data="userList.data"
                    tooltip-effect="dark"
                    border
                    stripe
                    header-row-class-name="tbHeader"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="id"
                        label="用户ID"
                        >
                    </el-table-column>
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="token"
                        label="token"
                        >
                    </el-table-column>
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="father"
                        label="father"
                        >
                    </el-table-column>
                    <el-table-column
                        v-if="isShowIdColumn"
                        prop="expressTime"
                        label="expressTime"
                        >
                    </el-table-column>
                    <el-table-column
                        type="index"
                        label="序号"
                        width="50"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="nickName"
                        label="昵称">
                    </el-table-column>
                    <el-table-column
                        label="手机号"
                        prop="userName">
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="注册日期">
                    </el-table-column>
                    <el-table-column
                        prop="lastLoginTime"
                        label="最后登录日期">
                    </el-table-column>
                    <el-table-column
                        prop="expressTimeS"
                        label="过期日期">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.isExpress ==0 ? 'success' : scope.row.isExpress == 1 ? 'warning' : 'danger'"
                            disable-transitions>{{ scope.row.expressTimeS }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="状态">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.status === 1 ? 'success' : 'danger'"
                            disable-transitions>{{scope.row.status==1?"有效":"无效"}}</el-tag>
                            &nbsp;
                            <el-tag
                            :type="scope.row.isExpress === 0 ? 'success' : scope.row.isExpress == 1 ? 'warning' : 'danger'"
                            disable-transitions>{{scope.row.isExpress==0?"有效":scope.row.isExpress==1?"7天内过期":"已过期"}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="isSupper"
                        label="用户类型">
                        <template slot-scope="scope">
                            <el-tag
                            :type="scope.row.isSupper === 1 ? '' : 'info'"
                            disable-transitions>{{scope.row.isSupper==1?"超级管理员":"普通用户"}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="TGmark"
                        label="推广码">
                    </el-table-column>
                    <el-table-column
                        prop="father"
                        label="上级">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100">
                        <template slot-scope="scope">
                            <el-button type="success" size="small" @click="handleClick_Edit(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="userList.count"
                    :page-size="pageSize"
                    @current-change="initUserList">
                </el-pagination>
            </el-col>
        </el-row>
        
        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogUserAdd" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="5">&nbsp;</el-col>
                <el-col :span="14">
                    <el-card>
                        <el-form ref="userForm" :model="form" label-width="80px">
                            <el-form-item label="昵称">
                                <el-input v-model="form.nickName"></el-input>
                            </el-form-item>
                            <el-form-item label="手机号">
                                <el-input v-model="form.userName"></el-input>
                            </el-form-item>
                            <el-form-item label="状态" style="text-align:left;">
                                <el-radio-group v-model="form.status">
                                    <el-radio :label="1" border>有效</el-radio>
                                    <el-radio :label="0" border>无效</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="类型" style="text-align:left;">
                                <el-radio-group v-model="form.isSupper">
                                    <el-radio :label="0" border>普通用户</el-radio>
                                    <el-radio :label="1" border>超级管理员</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="过期时间">
                                <el-date-picker type="datetime"
                                 placeholder="选择日期" 
                                 v-model="form.expressTimeS" 
                                 style="width: 100%;"
                                 default-time="23:59:59"
                                 @change="handleDateChange"></el-date-picker>
                            </el-form-item>
                            <el-form-item label="推广人">
                                <el-input v-model="form.tGMark"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="5"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>
.tbHeader,.tbHeader th{
    background-color: #fafafa !important;
    color:#333;
    font-weight: 500;
}
.title{
    font-size: 20px;
    text-align: left; 
    /* text-indent: 2em; 
    background: #f5f7fa75;  */
    height: 30px; 
    line-height: 30px;
    margin-bottom: 20px;
}
.query{
    margin-bottom: 20px; 
    text-align: left;
}
/* .query .el-input{
    width:150px ;
} */
.query .content .el-button{
    margin-left:10px;
}
</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'userManage',
        data() {
            return {
                dialogUserAdd:false,
                dialogUserEdit:false,
                isShowIdColumn:false,
                query:{
                    userName:'',
                },
                pageSize:10,
                userList:[],

                form:{
                    id:0,
                    nickName:'',
                    userName:'',
                    expressTime:0,
                    expressTimeS:'',
                    status:1,
                    isSupper:0,
                    tGMark:'',
                    title:''
                },
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initUserList(1)
            },
            closeDialog(){
                this.form.id=0;
                this.form.nickName=''
                this.form.userName=''
                this.form.expressTime=''
                this.form.expressTimeS=''
                this.form.status=1
                this.form.isSupper=0
                this.form.tGMark=''
                this.form.title=''
                this.dialogUserAdd=false
            },
            greCurrentStamp(){
                let timestamp = Date.now();
                return timestamp;
            },
            async initUserList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'admin/user/list';
                    const response = await axios.post(loginUrl, {
                        userName:this.query.userName,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        resD.data.forEach(u => {
                            let timestamp = this.greCurrentStamp()
                            let timestamp1 = timestamp+7*24*60*60*100;
                            if(u.expressTime*1000<timestamp){
                                u.isExpress = 2
                            }else if(u.expressTime*1000>=timestamp && u.expressTime*1000<timestamp1){
                                u.isExpress = 1
                            }else {
                                u.isExpress = 0
                            }
                            u.expressTimeS = $conf.common.formattedDate(u.expressTime);
                        });
                        this.userList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            handleSelectionChange(){

                
            },
            handleDateChange(value){
                this.form.expressTime = $conf.common.formatTimeStamp(value)/1000
            },
            toAdd(){
                this.dialogUserAdd=true
                this.form.id=0;
                this.form.nickName=''
                this.form.userName=''
                this.form.expressTime=''
                this.form.expressTimeS=''
                this.form.status=1
                this.form.isSupper=0
                this.form.tGMark=''
                this.form.title="添加用户"
            },
            handleClick_Edit(row){
                this.dialogUserAdd=true
                this.form.title="编辑用户"
                this.form.id=row.id;
                this.form.nickName=row.nickName
                this.form.userName=row.userName
                this.form.expressTime=row.expressTime
                this.form.expressTimeS=row.expressTimeS
                this.form.status=row.status
                this.form.isSupper=row.isSupper
                this.form.tGMark=row.father
                console.log(row);

            },
            async onSubmit(){
                console.log(this.form)
                let vm = this
                try {
                    const loginUrl=$conf.dev.apiUrl+'admin/user/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        nickName:this.form.nickName,
                        userName:this.form.userName,
                        status:this.form.status,
                        isSupper:this.form.isSupper,
                        expressTime:this.form.expressTime,
                        father:this.form.tGMark
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.msg,
                            duration:1000,
                            onClose:function(){
                                vm.initUserList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>