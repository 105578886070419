<template>
  <div class="col-content">
    <vue-markdown :source="markdownContent" />
  </div>
</template>

<style scoped>
.col-content{
    line-height: 30px;
    padding: 20px; 
    text-align: left;
}
</style>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  components: {
    VueMarkdown,
  },
  data() {
    return {
      markdownContent: `# 启鹏AI用户协议 \n 更新时间: 2024-05-01 
      \n欢迎您使用启鹏AI产品及服务! 本协议由启鹏AI提供者深圳市盐田区启鹏企划工作室(以下简称“启鹏AI”, “我们”)与您(若使用者为自然人)或您所代表的实体(若使用者为法人)订立, 约束您对qipengai.com网站等相关服务(以下简称“本服务”或“启鹏AI服务”)的使用。
      \n使用本服务即表示您通过电子方式确认本协议内容,同意本协议约束。如果您或您的监护人不同意本协议的任何内容,您应该立即停止使用启鹏AI服务。如您未经监护人同意使用本服务, 则您及您的监护人应依法承担因此导致的一切后果。
      \n我们特别提醒您在使用本服务之前认真阅读(未满18周岁的未成年人应在法定监护人陪同下阅读)、充分理解本协议的全部条款, 尤其是服务费用、免责声明、法律管辖和适用等加粗显示的条款。如您不同意本协议的任何条款, 将导致我们无法为您提供完整的产品和服务, 您可以选择停止使用本服务。如您自主选择同意本协议, 或您的注册、登录、使用等行为将视为您对本协议的接受, 并视为您接受并遵守本协议各项条款。
      \n如对本协议内容有任何疑问、意见或建议, 您可通过发送邮件至【service@qipengai.com】与我们联系。
      \n ## 第1条 定义 
      \n 1.1 用户: 指通过qipengai.com网站使用启鹏AI服务的所有自然人。本协议中可称为“用户”或“您”。
\n1.2 启鹏AI: 指启鹏依托百度文心大模型的技术推出的生成类人工智能问答产品。
\n1.3 输入: 指用户为使用本服务在对话框进行的文本输入。
\n1.4 输出: 指本服务根据用户输入而相应输出的文字、表格、代码和图片等内容。
\n## 第2条 服务介绍
\n2.1 启鹏AI依托百度文心大模型技术, 提供人工智能问答服务。用户需在对话框输入文本信息, 启鹏AI会基于用户输入输出相应的内容作为响应, 包括文字、表格、代码和图片等。启鹏AI不会收集和分析用户的输入和输出信息。
\n2.2 本服务具体功能将随着用户反馈、技术升级, 或相关监管政策的变化不断迭代更新。
\n2.3 您在使用本服务某一特定功能时, 针对该特定功能可能会另有单独的协议、相关业务规则等(“具体协议”), 您在使用该项特定功能前请阅读并同意相关的具体协议。本协议与具体协议的内容存在冲突的, 以具体协议的规定为准。
\n2.4 您在使用本服务中由第三方(比如通过插件、链接等)提供的产品或服务时, 请遵守第三方公布的用户协议、使用规则、隐私政策等法律文件, 在法律法规允许的范围内, 我们无法对第三方产品及任何内容负责。
\n## 第3条 账号登录
\n3.1 为访问本服务, 您应当按照页面引导输入您的启鹏AI账号、密码、手机号, 验证通过并签署本协议后, 即完成网页端注册和登录。账号注册过程中, 您应当仔细阅读《启鹏AI用户协议》、《启鹏隐私权保护声明》的条款并按照页面上的提示完成全部的注册程序。
\n3.2 本服务主要面向成人。如果您未满18周岁, 请在法定监护人陪同下阅读本协议, 并在征得法定监护人的同意后使用本服务。启鹏AI充分理解保护未成年人的重要性, 并会根据法律法规的要求及行业主流实践采取相应的保护措施。与此同时, 请监护人合理引导和约束未成年人用户对本服务的使用, 共同营造良好网络环境, 帮助未成年人养成良好上网习惯, 避免过度依赖或者沉迷本服务。
\n3.3 为落实国家法律法规的有关实名认证的要求, 使您更好地使用启鹏AI产品及相关服务, 保障您的账号安全, 您在注册时需要向我们提供真实的手机号码。此外, 为了向您提供已有或未来上线的部分功能, 我们可能还会严格依照法律法规要求向您收集其他个人信息。
\n3.4 您理解并承诺, 您所设置的账号不得违反国家法律法规相关规定, 您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息, 未经他人许可不得用他人名义(包括但不限于冒用他人姓名、名称、字号、头像等足以让人混淆的方式)开设账号, 不得恶意注册账号(包括但不限于频繁注册、批量注册账号等行为)。您在账号注册及使用过程中需遵守相关法律法规, 不得实施任何侵害国家利益、损害其他公民合法权益、有害社会道德风尚的行为。启鹏AI有权对您提交的信息进行审核。
\n3.5 为符合相关法律法规的要求, 保障您的账号安全, 启鹏AI可能会定期或不定期采用不同的方式对您的身份进行验证, 包括但不限于要求输入验证码、手机号验证、邮箱认证等。如您无法完成验证或拒绝验证的, 启鹏AI可能合理怀疑您的账号存在安全风险, 并暂停向您提供本服务的部分或全部服务, 并有权采取进一步措施。
\n3.6 通常而言本服务对用户认定标准为: 同一启鹏AI账号、手机号、支付账号、银行卡号等均视为一个用户。
\n3.7 您应该自行保管好您的账号信息(包括账号、密码、验证码等)。因您对账号信息保管不当、主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果, 均由您自行承担如发现任何未经授权使用您账号登录使用本服务或其他可能导致您账号遭窃、遗失的情况, 建议您立即通知启鹏AI。您理解启鹏AI对您的请求采取行动需要合理时间, 除启鹏AI存在过错外, 启鹏AI对在采取行动前已经产生的后果不承担责任。
\n3.8 您可以随时注销您的账号并终止与启鹏AI的服务关系, 但请您理解, 即使您的账号已注销, 您仍需对您注销前的账号使用行为承担相应法律责任。特别地, 在您注销账号前, 您应确保账号不存在未完结交易或其他未了结的权利义务, 且不存在其他因注销可能引发纠纷或者导致已发生的纠纷难以解决的情况, 包括但不限于您的账号被冻结、限制, 您的注销是出于躲避调查取证的目的等。请注意, 若我们发现您在使用本服务过程中存在违法行为, 我们将依法保存该等记录。
\n3.9 如您的账号有余额, 请提出或转出账号内全部余额。特别提醒您, 一旦您注销本服务账号, 如届时该账号下还存在虚拟收益的(包括但不限于积分、虚拟货币、已经购买但未到期或未使用完的增值服务等), 启鹏AI有权对该账号下的全部虚拟收益做清除处理, 因此产生的后果由您自行承担。
\n3.10 启鹏AI发现账号使用者并非账号初始注册人时, 有权暂停向用户提供本服务的部分或全部服务, 并有权采取进一步措施。您不应将账号或密码转让、出借、销售或以任何脱离您控制的形式交由他人使用, 您对使用您的账号发生的所有活动负责, 且转让、出借账号导致的任何后果均由您本人承担。
\n## 第4条 服务使用规范
\n4.1 基于您对本服务的使用, 启鹏AI许可您一项个人的、基于法定或约定事由可撤销的、不可转让的、非独占的和非商业的使用本服务进行内容输入并获得内容输出的权利。本协议未明示授权的其他一切权利仍由启鹏AI保留, 您在行使该些权利前须另行获得启鹏AI的书面许可, 同时启鹏AI如未行使前述任何权利, 并不构成对该权利的放弃。如果您对外发布或传播本服务生成的输出, 您应当: (1)主动核查输出内容的真实性、准确性, 避免传播虚假信息；(2)以显著方式标明该输出内容系由人工智能生成, 以向公众提示内容合成的情况；(3)避免发布和传播任何违反本协议使用规范的输出内容。
\n4.2 用户在使用启鹏AI提供的上述服务时, 应遵守本协议, 遵守自愿、平等、公平及诚实信用原则, 不利用本服务侵犯他人合法权益及谋取不正当利益, 不扰乱互联网平台正常秩序。
\n4.3 为履行法定合规要求, 启鹏AI有权采取技术手段或人工手段对用户使用本服务的行为及信息进行审查, 包括但不限于对输入和输出进行审查、建立风险过滤机制、建立违法内容特征库等。对于违规用户, 启鹏AI有权按照本协议第八条的规定进行处置。
\n4.4 启鹏AI提示您, 作为本服务的使用者, 您在使用上述服务时应积极承担信息安全义务。您承诺不对本服务采取以下行为: 
\n4.4.1 通过输入, 诱导生成违反相关法律法规的输出, 包括但不限于: 
\n(1) 反对宪法所确定的基本原则。
\n(2) 危害国家安全, 泄露国家秘密, 颠覆国家政权, 推翻社会主义制度, 破坏国家统一。
\n(3) 损害国家荣誉和利益。
\n(4) 煽动民族仇恨、民族歧视, 破坏民族团结。
\n(5) 侮辱、滥用英烈形象, 否定英烈事迹, 美化粉饰侵略战争行为的。
\n(6) 破坏国家宗教政策, 宣扬邪教和封建迷信。
\n(7) 散布谣言、虚假有害信息, 扰乱社会秩序, 破坏社会稳定。
\n(8) 宣扬淫秽、色情、赌博、暴力、凶杀、恐怖主义、极端主义或者教唆犯罪。
\n(9) 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序。
\n(10) 诽谤他人, 泄露他人隐私, 侵害他人名誉权、肖像权、隐私权、知识产权和其他合法权益。
\n(11) 破坏国际关系以及国际和平稳定。
\n(12) 含有法律、行政法规禁止的其他内容的信息。
\n4.4.2 通过输入, 诱导生成不友善对话的输出, 包括但不限于: 
\n(1) 人身攻击及辱骂他人。
\n(2) 针对以下群体发表诅咒、歧视、漠视生命尊严等性质的言论, 群体包括: 不同特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。
\n(3) 对他人进行诅咒、恐吓或威胁。
\n(4) 对其他用户创作的内容直接进行贬低性的评论。
\n(5) 对其他用户使用粗俗用语, 并产生了冒犯。
\n(6) 针对以下群体发表偏见性质的言论, 群体包括: 特定国籍、地域、性别、性别认同、性倾向、种族、民族、健康状况、职业、年龄、信仰、残障群体等。
\n4.4.3 通过输入, 恶意对抗本服务的过滤机制, 包括但不限于: 
\n(1) 输入难以辨识涵义影响阅读体验的字符、数字等无意义乱码。
\n(2) 恶意对抗行为, 包括但不限于使用变体、谐音等方式规避服务检测来输入违反上述4.4.1和4.4.2的言论。
\n4.4.4 干扰本服务正常运行以及损害启鹏AI合法权益, 包括但不限于: 
\n(1) 利用本服务从事窃取商业秘密、窃取个人信息等违法犯罪活动。
\n(2) 对本服务进行反向编译、反向工程或者以其他任何途径获得与本服务算法相关的任何信息。
\n(3) 利用本服务开发与本服务存在竞争关系其他产品和服务(除非该限制违法相关法律规范)。
\n(4) 以任何方式(包括但不限于通过任何机器人、蜘蛛、其他自动设置、设置镜像)来抓取、复制本服务及其所包含的任何内容。
\n(5) 未经启鹏AI同意或未有合法正当依据, 擅自去除、篡改本服务涉及的人工智能生成标识或深度合成内容显著标识。
\n(6) 将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料, 加以上载、发布、发送电子邮件或以其他方式传送。
\n(7) 非法入侵本服务服务器、篡改本服务之相关代码或其他损害本服务的行为。
\n(8) 企图规避启鹏AI平台的安全设置或网络系统, 包括获取用户不应获取的数据, 登录未经明确授权的服务器或账号, 或采用运行端口扫描等方式窥探其他网络的安全措施。
\n(9) 以导致启鹏AI的计算机系统或设施承受不合理负载的方式获取输出, 或进行可能导致此类情况出现的行为。
\n(10) 未经启鹏AI授权, 非法转售或对外提供启鹏AI服务, 或以商业化目的使用启鹏AI服务。
\n4.4.5 其他违反法律法规规定、侵犯他人以及启鹏AI合法权益的行为, 例如实施垄断或不正当竞争行为等。
\n## 第5条 知识产权和其他权利
\n5.1 启鹏AI为本服务的开发、运营主体, 并对本服务的开发和运营等过程中产生的所有数据、信息、输出等享有法律法规允许范围内的全部权利, 但相关权利人依照法律规定应享有权利的除外。
\n5.2 启鹏AI在本服务中提供的内容(包括但不限于软件、技术、程序、代码、用户界面、网页、文字、图片、图像、音频、视频、图表、版面设计、商标、电子文档等)的知识产权(包括但不限于著作权、商标权、专利权和其他知识产权)属于启鹏AI所有, 但相关权利人依照法律规定应享有权利的除外。
\n5.3 您理解并承诺, 您在使用本服务时的输入不侵犯任何人的知识产权、肖像权、名誉权、荣誉权、姓名权、隐私权、个人信息权益等合法权益, 不涉及任何国家秘密、商业秘密、重要数据或其他可能会对国家安全或者公共利益造成不利影响的数据, 否则由此产生的侵权风险和责任由您承担。启鹏AI由此遭受的全部直接和间接损失(包括但不限于经济、商誉、维权支出、律师费等损失)也同样由您承担。
\n5.4 您知悉、理解并同意, 如果您按照法律规定对您的输入(包括您使用本服务过程中自行上传、发布的全部内容)和/或输出享有权利的(包括但不限于知识产权、肖像权等), 您使用本服务均不会造成前述合法权利的转移或减损, 除非我们与您另有约定。与此同时, 您理解并同意启鹏AI将在法律允许的范围内为实现本服务目的对您上传、发布的内容进行存储及使用(包括但不限于复制、分发、传送、公开展示、编辑等)。
\n## 第6条 个人信息保护
\n6.1 启鹏AI非常重视对用户个人信息的保护。启鹏AI将按照《启鹏AI个人信息保护规则》的规定收集、使用、储存和分享您的个人信息。《启鹏AI个人信息保护规则》为本协议不可分割的组成部分。
\n## 第7条 服务费用
\n7.1 启鹏AI服务分为免费服务和会员服务两种, 本服务的任何免费功能和服务不应视为启鹏AI放弃后续收费的权利。
\n7.2 您可通过购买会员服务、购买礼品卡等方式享受启鹏AI提供的会员收费服务。会员服务可能会在底座大模型、可选择插件等方面与免费服务不同。我们将在相关服务页面展示会员服务的收费标准与方式、会员权益等信息。如您选择使用会员服务, 请审慎阅读并充分理解《启鹏AI会员服务协议》。您亦可以到指定的页面自行取消会员服务。一旦取消成功, 您将仅可使用启鹏AI提供的免费服务。
\n7.3 除前述会员服务外, 启鹏AI可能在提供本服务过程中向您收取其他费用, 具体以届时启鹏AI就本服务对外公布的信息为准。如用户使用本服务中的其他收费项目, 应按照启鹏AI对应公布的计费标准和方式支付相关费用。
\n7.4 对于暂不收费的功能, 启鹏AI有权根据实际情况对暂不收费的服务内容、服务期限及服务标准等进行单方变更, 用户对此理解并接受前述的变更。
\n7.5 前述修改、变更或开始收费前, 启鹏AI将在相应服务页面进行通知或公告。如用户拒绝付费, 那么用户将不能继续使用本服务中的收费功能。
\n## 第8条 违约责任和处罚措施
\n8.1 因用户违反相关法律法规或本协议约定导致的任何后果, 均由用户独立承担责任、赔偿损失, 与启鹏AI无关。若因用户使用本服务的行为给启鹏AI或与启鹏AI公司合作的其他单位面临任何投诉、举报、质询、索赔、诉讼, 或者使启鹏AI公司或者与启鹏AI公司合作的其他单位因此遭受任何名誉、声誉或者财产上的损失, 您应积极地采取一切可能采取的措施, 以保证启鹏AI公司及与启鹏AI公司合作的其他单位免受上述索赔、诉讼的影响。同时您对启鹏AI公司及与启鹏AI公司合作的其他单位因此遭受的直接及间接经济损失负有全部的损害赔偿责任。
\n8.2 如果用户以任何手段干扰本服务的运行或干扰其他用户对本服务的使用的, 启鹏AI有权要求其赔偿损失(包括但不限于经济、商誉、维权支出、律师费等损失)。
\n8.3 如果用户有上述违反“第4条 服务使用规范”行为的, 启鹏AI有权采取警示、限制功能、暂停或终止提供服务、删除违法违规信息、锁定账号等启鹏AI认为合理的处理措施, 并保存有关记录。对处理结果存在疑议的, 用户可根据9.2条之规定向我们进行申诉, 我们将及时受理并进行处理和反馈。
\n8.4 启鹏AI依第8.3条采取限制功能、暂停或终止提供服务等处理措施的, 根据处理措施的不同, 用户可能短期或长期无法使用包括会员服务在内的启鹏AI服务, 启鹏AI有权不予退还用户剩余会员权益等增值服务对应的费用。
\n## 第9条 投诉、申诉处理
\n9.1 投诉。如果您认为本服务涉及侵害您知识产权或其他权利的, 或者您发现任何违法、虚假信息以及违反本协议的使用行为, 您可将相关投诉材料发送至该邮箱: service@qipengai.com。我们会在收到您的投诉材料后进行处理, 包括及时采取停止生成、停止传输、消除等合理处置措施。
\n9.2 申诉。如果您认为我们不恰当地限制了您使用本服务的能力, 您可以将相关申诉材料发送至该邮箱: service@qipengai.com申诉所需的材料至少包括您的账号信息、身份证或护照复印件(对自然人)、单位登记证明复印件(对单位)、账号当前状态、账号因何种使用情形被采取措施以及您希望我们采取何种措施恢复您使用本服务的能力。您提交申诉申请后, 我们将向您发送回执编号。您需记录回执编号以用于进度查询。申诉结果我们将以邮件方式通知您。
\n9.3 我们将在您提出投诉或申诉后的5个工作日内向您反馈相关情况。
\n## 第10条 免责声明
\n10.1 启鹏AI致力于提供安全、稳定、持续的服务, 保障用户正常使用, 但启鹏AI仅以“现状”、“当前功能”的状态提供本服务。启鹏AI不承担除法律有明确规定外的其他责任, 如: 不保证本服务在操作上不会中断或没有错误, 不保证在现有技术水平下有能力纠正本服务的所有缺陷, 亦不保证本服务能满足用户的所有要求。启鹏AI将在现有技术的基础上尽最大努力提供相应的安全措施以保障本服务的安全和正常运行, 包括但不限于通过限制用户单次可发送的服务器请求数量。在特定时间段内用户的请求数量达到风险预警上限后, 基于服务运营安全的考虑, 您的账号可能会被暂停。
\n10.2 由于可能存在的计算机病毒、网络通讯故障、系统维护等方面的因素以及可能发生的不可抗力事件或意外事件, 启鹏AI在此明确声明对本服务不作明示或暗示的保证, 包括对本服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途或满足用户的需求、使用效果等。
\n10.3 人工智能和机器学习是快速发展的研究领域, 启鹏AI也在不断努力改进本服务, 使其更加准确、可靠、安全、可信。但限于技术特性, 启鹏AI在尽到合理努力的前提下, 仍无法完全保证您通过本服务获得的输出的合法性、真实性、准确性以及完整性。您应对本服务中的输出自行加以判断, 并承担因使用内容引起的所有风险。我们提示您, 除法律有明确规定外, 启鹏AI无法也不会对前述风险而导致的任何损失或损害承担责任。
\n10.4 本服务下的输出不代表专业意见, 不能代替如法律、医疗、金融等专业领域从业人员向您解答对应疑问。相关观点也仅限于算法所触达有限数据呈现的供您参考的结果, 并不代表启鹏AI立场。由于机器学习特性, 就相同的输入可能产生不同的输出结果, 请您注意甄别。您根据输出的内容所作出的任何判断或者据此作出的后续相关操作行为, 以及由此带来的后果和责任均由您自行承担, 请科学理性认识和依法使用本服务。
\n## 第11条 法律管辖和适用
\n11.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国大陆地区法律。
\n11.2 本协议的签订、履行或解释发生争议, 双方应努力友好协商解决。如协商不成, 任何一方均有权向深圳市盐田区启鹏企划工作室住所地有管辖权的法院起诉。
\n## 第12条 本协议的更新与终止
\n12.1 您对本协议的接受包括接受我们对本协议任何条款随时所做的任何修改。我们有权随时修改本协议, 更新后的协议将通过官方网站公布等适当的方式进行公布。一旦公布即代替原协议条款, 请您自行在官方网站查阅最新版协议条款。如您不接受修改后的协议, 请您立即停止使用本服务。您继续使用本服务将被视为您已接受了修改后的协议。
\n深圳市盐田区启鹏企划工作室
\n更新日期: 2024年05月01日
\n生效日期: 2024年05月01日`,
    };
  },
};
</script>
