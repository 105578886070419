<template>
    <el-container height="100%">
        <el-main>
            <div ref="div_scroll" style="overflow-y: auto; height:100%;">
                <el-row class="row-h row-h-first">
                    <el-col :span="12" style="text-align: center;">
                        <div ref="qrcode" style="width: 200px; height: 200px;"></div>
                    </el-col>
                    <el-col :span="12" style="text-align: center; padding-top: 2vh;">
                        <div style="height: 50px; line-height: 50px; font-size: large; font-weight: 600;">这是您独有的推广方式</div>
                        <div style="height: 100px; line-height: 30px;">您可以将此二维码或推广链接发送给需要的伙伴，您也将因此获得收益，感谢您的支持。</div>
                    </el-col>
                </el-row>
                <el-row class="row-h row-h-first">
                    <el-col :span="24" style="text-align: center;">
                        <el-input 
                            type="text" 
                            v-model="qrCodeUrl" disabled>
                            <div slot="append">
                                <el-button icon="el-icon-copy-document" @click="copyLink">复制链接</el-button>
                            </div>
                            </el-input>
                    </el-col>
                </el-row>
            </div>
        </el-main>
    </el-container>
</template>

<script>
// import axios from 'axios'
// import $conf from '@/apiconfig'
import QRCode from 'qrcode'

    export default {
        name: 'onlineMarket',
        props:{
            vTGMark: {
                type: String,
                default: "",
            },
            typ:{
                type:String,
                default:"page"
            }
        },
        components: {
        },
        data()
        {
            return {
                content:"",
                chatList:[],
                // eventSource:null,
                messCont:'',
                isDisabledButton:false,
                you:"",
                qrCodeUrl:"https://qipengai.com"+"/#/veReg/"+this.vTGMark
            };
        }, 
        watch:{
            vTGMark(){
                // this.$message.info("init     "  + this.tid)
                if(this.vTGMark!=""){
                    this.init();
                }
            },
        },
        mounted(){
            // console.log(this.vMarkG+"   ||   "+this.typ)
            if(this.vTGMark!=""){
                this.init();
            }
        },  
        computed: {  
            iconClass() {  
                return this.isDisabledButton ? 'el-icon-loading' : 'el-icon-s-promotion';  
            },  
        },
        methods:{
            init(){
                this.initMarket()
            },
            async initMarket(){
                this.qrCodeUrl=window.location.origin+'/#/veReg/'+this.vTGMark
                // 使用 qrcode 的 toDataURL 方法生成二维码图片数据  
                QRCode.toDataURL(this.qrCodeUrl, {  
                        width: 200, // 宽度  
                        height: 200, // 高度  
                        color: {  
                            dark: '#000000', // 前景色（二维码颜色）  
                            light: '#ffffff' // 背景色  
                        }  
                    }, function (err, url) {  
                        if (err) {  
                            console.error(err);  
                            return;  
                        }  
                        
                        // 使用生成的二维码图片数据来显示二维码  
                        // 这里我们假设你有一个 img 元素，你可以通过 ref 或其他方式获取它  
                        // 但是 qrcode.toDataURL 返回的是 base64 编码的图片 URL，可以直接设置为 img 的 src  
                        // 但由于我们直接在 div 中展示，需要稍微变通一下  
                
                        // 创建一个 img 元素并设置其 src 属性为生成的 URL  
                        const img = new Image();  
                        img.src = url;  
                
                        // 将 img 元素添加到我们的 qrcode div 中  
                        // 这里为了简单起见，我们直接设置 qrcode div 的背景图片  
                        // 注意：这只是一个示例，实际上你可能需要更复杂的 DOM 操作或使用其他库来展示图片  
                        this.$refs.qrcode.style.backgroundImage = `url(${url})`;  
                        this.$refs.qrcode.style.backgroundSize = 'cover';  
                
                        // 如果你想要将图片插入到 DOM 中，而不是作为背景，你可能需要创建一个 img 元素并添加到 DOM 中  
                        // 例如：  
                        // this.$refs.qrcode.appendChild(img);  
                    }.bind(this)); // 注意这里使用 bind(this) 来确保回调函数中的 this 指向 Vue 实例  

            },
            async copyLink(){
                try {
                    const shareLinText="叮！点击链接 "+this.qrCodeUrl+" -- 注册帐号，一起开启和启鹏AI的奇妙之旅吧~ \n启鹏Ai，既能写文案、想点子，又能陪你聊天，答疑解惑，是你工作学习生活的智能伙伴！"
                    await navigator.clipboard.writeText(shareLinText);  
                    this.$message.success("链接复制成功。");  
                } catch (err) {
                    console.error(err);  
                    this.$message.info("链接复制失败。");  
                }  

            },
            toQiPengAi(){
                this.$router.replace('/')
            }
                
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
height: 100%;
overflow: hidden;
    color: #000;

}
.el-footer {
    background-color: #ffffff;
    color: #000;
    text-align: center;
    height: 120px;
    border-top: 1px solid #f4f4f4;
}
.div_init{
   margin-top: 30vh;
}
.div_init_t1{
   font-size: 20pt;
   font-weight: 700;
}
.div_init_t2{
    margin-top: 10px;
    /* padding-left: 25%; */
    font-size: 12pt;
    font-weight: 500;
    text-align: center;
}
.el-main{
    overflow-y: auto;
    color: #000;
}
.row-h{
    min-height: 40px;
    margin-top: 20px;
}
.row-h-first{
    margin-top: 0;
}
.el-col-2{
    min-height: 40px;
    line-height: 40px;
}
.el-col-24{
    margin-top: 10px;
}
.el-col-title{
    font-weight: 600;
    margin-top: none;
}
.aiContent{
    padding: 10px;
    background: #f5f5f5;
    border-radius: 15px;
    /* line-height: 1.6; */
}
.col-content{
    line-height: 30px;
}
.col-rechat{
    text-align: right;
    margin-bottom: 15px;
}
</style>
