<template>
    <div>
        
        <el-row>
            <el-col :span="24">
               <div class="title">模板管理</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                模板名称：
                                <el-input
                                    type="text"
                                    placeholder="模板名称："
                                    v-model="query.tempName"
                                    style="width: 150px !important;">
                                </el-input>
                                模板编号：
                                <el-input
                                    type="text"
                                    placeholder="模板编号："
                                    v-model="query.tempNo"
                                    style="width: 150px !important;">
                                </el-input>
                                模板分类：
                                <el-cascader
                                    placeholder="搜索分类"
                                    v-model="query.libId"
                                    :options="libData"
                                    :props="{ 
                                        multiple: true,
                                        value:'id',
                                        label:'libname'
                                     }"
                                    filterable></el-cascader>
                                <el-button type="success" @click="initList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <el-button type="primary" @click="handleClick_Upload">批量上传</el-button>
                            <el-button type="primary" @click="handleClick_Add(0)">添加模板</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-table
                    :data="tempList.data"
                    style="width: 100%"
                    row-key="id"
                    border
                    lazy>
                    <el-table-column
                        prop="id"
                        label="序号"
                        width="180"
                        hidden>
                    </el-table-column>
                    <el-table-column
                        prop="libId"
                        label="分类"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="libName"
                        label="分类"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="tempName"
                        label="模板名称">
                    </el-table-column>
                    <el-table-column
                        prop="tempNo"
                        label="模板编号">
                    </el-table-column>
                    <el-table-column
                        prop="tempDis"
                        label="模板说明">
                    </el-table-column>
                    <el-table-column
                        prop="tempParam"
                        label="模板参数">
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        align="right"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button type="success" size="small" @click="handleClick_Edit(scope.row)">编辑</el-button>
                            <el-button type="danger" size="small" @click="handleClick_Delete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="tempList.count"
                    :page-size="pageSize"
                    @current-change="initList">
                </el-pagination>
            </el-col>
        </el-row>

        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogAdd_Edit" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="5">&nbsp;</el-col>
                <el-col :span="14">
                    <el-card>
                        <el-form ref="targetForm" :model="form" label-width="80px">
                            
                            <el-form-item label="模板名称">
                                <el-input v-model="form.tempName"></el-input>
                            </el-form-item>
                            <el-form-item label="模板编号">
                                <el-input v-model="form.tempNo"></el-input>
                            </el-form-item>
                            <el-form-item label="模板分类" style="text-align: left;">
                                <el-cascader
                                    placeholder="分类"
                                    v-model="form.libId"
                                    :options="libData"
                                    :props="{ 
                                        multiple: false,
                                        value:'id',
                                        label:'libname'
                                     }"
                                    filterable></el-cascader>
                            </el-form-item>
                            <el-form-item label="状态" style="text-align:left;">
                                <el-radio-group v-model="form.status">
                                    <el-radio :label="1" border>有效</el-radio>
                                    <el-radio :label="2" border>无效</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="模板说明">
                                <el-input v-model="form.tempDis"
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入模板的说明"></el-input>
                            </el-form-item>
                            <el-form-item label="模板参数">
                                <el-input v-model="form.tempParam"
                                    type="textarea"
                                    :rows="5"
                                    placeholder="请输入模板参数，用于使用指引"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="5"></el-col>
            </el-row>
        </el-drawer>

        <!-- Dialog组件 上传  -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogUpload" title="批量上传" size="50%" direction="rtl" @close="dialogUpload = false">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="2">&nbsp;</el-col>
                <el-col :span="20">
                    <el-card>
                        <el-upload
                            ref="upload"
                            class="upload-demo"
                            drag
                            :limit="1"
                            :action="upfileUrl"
                            accept=".xls,.xlsx"
                            :on-success="successUpload"
                            :on-error="errorUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传.xls/.xlsx文件，且不超过2mb</div>
                        </el-upload>
                        <br><br>
                        <el-button @click="dialogUpload = false">取消</el-button>
                    </el-card>
                </el-col>
                <el-col :span="2"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>

</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'tempList',
        data() {
            return {
                libData:[],

                tempList:{},
                pageSize:10,
                query:{
                    libId:0,
                    tempName:'',
                    tempNo:''
                },
                upfileUrl:$conf.dev.apiUrl+'temp/list/upload',
                dialogAdd_Edit:false,
                dialogUpload:false,
                form:{
                    title:'',
                    id:0,
                    libId:0,
                    tempName:'',
                    tempNo:'',
                    tempDis:'',
                    tempParam:'',
                    status:1,
                }
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initList(1);
                this.initLibList(1);
            },
            async initLibList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'temp/lib/list';
                    const response = await axios.post(loginUrl, {
                        libName:this.query.libName,
                        pageSize:10000,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.libData=resD.data;
                    }else{
                        console.log(resD.msg);  
                    }
                } catch (error) {  
                    console.log(error);  
                } 
            },
            async initList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'temp/list/list';
                    let aLib=[];
                    if(typeof this.query.libId === 'number'){
                        aLib = new Array();
                        
                    }else if(Array.isArray(this.query.libId)){
                        aLib = this.query.libId.map(row => row[2]);
                    }
                    const response = await axios.post(loginUrl, {
                        alib:aLib,
                        tempName:this.query.tempName,
                        tempNo:this.query.tempNo,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.tempList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);
                } 
            },
            handleClick_Upload(){
                this.dialogUpload = true;

                this.$refs.upload.clearFiles(); // 清除已上传文件
            },
            successUpload(response, file, fileList) {
                console.log('上传成功:', response, file, fileList);
                // 在这里处理上传成功的逻辑
                if(file.status=='success'){
                    this.$message.success(response);
                    this.dialogUpload = false;
                    this.initList();
                }
            },
            errorUpload(error, file, fileList) {
                console.error('上传失败:', error, file, fileList);
                // 在这里处理上传失败的逻辑
                    this.$message.error(error);
            },
            handleClick_Edit(row){
                this.dialogAdd_Edit=true
                this.form.title="编辑模板"
                
                this.form.id=row.id
                this.form.libId=row.libId
                this.form.tempName=row.tempName
                this.form.tempNo=row.tempNo
                this.form.tempDis=row.tempDis
                this.form.tempParam=row.tempParam
                this.form.status=row.status
                console.log(row);
            },
            closeDialog(){
                this.dialogAdd_Edit=false
                this.form.title=""
                this.form.id=0
                this.form.libId=0
                this.form.tempName=''
                this.form.tempNo=''
                this.form.tempDis=''
                this.form.tempParam=''
                this.form.status=1
            },
            handleClick_Add(){
                this.dialogAdd_Edit=true
                this.form.title="添加模板"
                this.form.id=0
                this.form.libId=[0,0,0]
                this.form.tempName=''
                this.form.tempNo=''
                this.form.tempDis=''
                this.form.tempParam=''
                this.form.status=1
            },
            async handleClick_Delete(row){
                try {
                    const vm = this
                    this.$confirm('此操作将永久删除该模版, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(async () =>  {
                        const loginUrl=$conf.dev.apiUrl+'temp/list/delete';
                        const response = await axios.post(loginUrl, {
                            id:row.id,
                        },{
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });  
                        const resD = response.data;
                        if(resD.code==0){
                            this.$message.success({
                                message:resD.msg,
                                duration:1000,
                                onClose:function(){
                                    vm.initList(1)
                                    vm.closeDialog()
                                }
                            })
                        }else{
                            this.$message.error(resD.msg);  
                        }
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
                    });
                    
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async onSubmit(){
                try {
                    console.log(this.form);

                    if(this.form.tempName === ''){
                        this.$message.error('模板名称必填');
                        return false;
                    }
                    if(this.form.tempNo === ''){
                        this.$message.error('模板编号必填');
                        return false;
                    }
                    console.log(this.form.libId == 0,this.form.libId)
                    if((typeof this.form.libId == 'number' && this.form.libId == 0)
                || (Array.isArray(this.form.libId) && this.form.libId[2] == 0)){
                        this.$message.error('模板分类必填');
                        return false;
                    }
                    const vm = this
                    const loginUrl=$conf.dev.apiUrl+'temp/list/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        tempName:this.form.tempName,
                        tempNo:this.form.tempNo,
                        tempDis:this.form.tempDis,
                        tempParam:this.form.tempParam,
                        status:this.form.status,
                        libId:this.form.libId[2]
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.msg,
                            duration:1000,
                            onClose:function(){
                                vm.initList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>