<template>
    <div class="logo">
        <img alt="启鹏Ai logo" class="lg" src="../../assets/logo.png">
        <div class="layui-col-sm12 layui-col-md12">
            <fieldset class="logo_title layui-elem-field layui-field-title" style="margin-top: 20px; margin-bottom: 20px;">
                <legend> 后台管理系统·登录 </legend>
            </fieldset>
        </div>
        <div class="login">
            <div class="layui-row layui-col-space15">
                    <div class="layui-col-sm12 layui-col-md12">
                        <div class="layui-form-item">
                            <input type="text" name="phone" v-model="phone" autocomplete="off" placeholder="帐号" class="layui-input">
                            <i class="layui-icon layui-icon-username zyl_lofo_icon"></i>
                        </div>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <div class="layui-form-item">
                            <input type="password" name="password" v-model="password" lay-affix="eye"  autocomplete="off" placeholder="密码" class="layui-input">
                            <i class="layui-icon layui-icon-password zyl_lofo_icon"></i>
                        </div>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <div class="layui-row">
                            <div class="layui-col-xs6 layui-col-sm6 layui-col-md6">
                                <div class="layui-form-item">
                                    <input type="text" name="vercode" v-model="code" id="vercode" autocomplete="off" placeholder="验证码" class="layui-input" maxlength="6">
                                    <i class="layui-icon layui-icon-vercode zyl_lofo_icon"></i>
                                </div>
                            </div>
                            <div class="layui-col-xs2 layui-col-sm2 layui-col-md1">&nbsp;</div>
                            <div class="layui-col-xs4 layui-col-sm4 layui-col-md4">
                                <div class="zyl_lofo_vercode" id="imgCode" data-type="reCode"><img :src='codeImage' style='height:38px;' @click="getVerCode"></div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <button class="layui-btn layui-bg-blue layui-btn-fluid"  @click="toLogin">立即登录</button>
                    </div>
            </div>
        </div>
        <div class="foot">
            Copyright 2024 ©启鹏工作室 版权所有 备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><u>粤ICP备2024240429号-1</u></a>
        </div> 
    </div>
</template>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'
import md5 from 'js-md5'


export default {
name: 'indexAdm',
data()
{
    return {
        LGOIN_CODE: 0,  
        BIZID: "",  
        vodeA: undefined,
        phone:"",
        password:"",
        code:"",
        machineCode:"",
        codeImage:""
    };
},
created(){
    this.init();
},
methods:{
    init:function(){
        this.getVerCode();
    },
    greMachineCode(){
        let timestamp = Date.now();
        this.machineCode = md5(String(timestamp))
    },
    async getVerCode(){
        this.greMachineCode();
        try{
            const url=$conf.dev.apiUrl+'admin/login/code';
            const response = await axios.post(url,{
                key:this.machineCode
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });  
            const resD = response.data;
            // if(resD.code==0){
            //     this.codeImage = resD.data
            // }else{
            //     this.$message.error(resD.msg);  
            // }
            this.codeImage = 'data:image/png;base64,'+resD
        }catch (error) {  
            this.$message.error(error);  
        }  
    },
    toLogin: async function(){
        try {  
            this.validate();
            const loginUrl=$conf.dev.apiUrl+'admin/login';
            const response = await axios.post(loginUrl,{
                phone: this.$data.phone,
                password:md5(this.$data.password),
                code: this.$data.code,
                key:this.machineCode
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });  
            const resD = response.data;
            if(resD.code==0){
                cookieH.set("adminTK",resD.data,{expires:7});
                
                this.$router.replace('/admin/center');
            }else{
                this.$message.error(resD.msg);  
            }
        } catch (error) {  
            this.$message.error(error);  
        }  
    },
    validate:function(){
        if(this.$data.phone == ""){
            this.$message.error("手机号码必填");
            return false;
        }
        if(this.$data.password == ""){
            this.$message.error("密码必填");
            return false;
        }
        if(this.$data.code == ""){
            this.$message.error("验证码必填");
            return false;
        }
    }
    ,getDeviceType() {  
        let isMobile = false;
        // 首先检查navigator.maxTouchPoints，这是一个更可靠的方法  
        if ('maxTouchPoints' in navigator) {  
            isMobile = navigator.maxTouchPoints > 0?true:false;
            return isMobile ? 'mobile' : 'desktop';  
        }  
        else{
            
            // 常见的移动设备用户代理片段  
            const mobileUserAgentPatterns = [  
                /Android/i,  
                /webOS/i,  
                /iPhone|iPad|iPod/i,  
                /BlackBerry/i,  
                /Windows Phone/i,  
                /Opera Mini/i,  
                /IEMobile/i,  
                /Mobile/i, // 通用移动标识  
                /Mobile Safari/i,  
                /Opera Mobi/i,  
                /Tablet/i, // 平板电脑通常也作为移动设备处理  
                /Mobile MSIE/i,  
                /Windows CE/i,  
                /UCWEB/i,  
                /UC Browser/i,  
                /MiuiBrowser/i,  
                /Mobile Safari/i,  
                /Chrome Mobile/i,  
                /Firefox Mobile/i  
            ];  
            
            // 获取用户代理字符串  
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
            
            // 检查用户代理是否包含上述任何移动设备标识  
            for (let i = 0; i < mobileUserAgentPatterns.length; i++) {  
                if (userAgent.match(mobileUserAgentPatterns[i])) {  
                return 'mobile'; // 是移动设备  
                }  
            }  
            return 'desktop';
        }
       
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo{
    text-align: center;
    height: 100%;
    background: url('../../assets/bg.jpg') 0px -200px
}
.logo .lg{
    width: 200px;
    margin-top: 40px;
}
.login {
    width: 350px; /* 设置固定宽度 */  
    height: 250px; /* 设置固定高度 */  
    border: 1px solid #ccc; /* 设置边框 */  
    border-radius: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 设置阴影 */  
    display: flex; /* 如果需要内部内容垂直居中 */  
    align-items: center; /* 内部内容垂直居中 */  
    justify-content: center; /* 内部内容水平居中 */  
    /* 如果需要水平居中，可以添加 margin: auto; 但这通常与 flex 布局一起使用 */  
    margin: auto; /* 当 login 是其容器的直接子元素且其父容器为 flex 容器时有效 */  
    text-align: center; /* 如果 login 内只有文本，确保文本居中 */ 
    margin-top: 50px; 
    padding: 2%;
}


.zyl_lofo_icon{
    position: absolute;
    left: 5px; 
    top: 8px; 
    font-size: 20px;
    /* color:#FFF; */
    color: #000000;
}
.layui-form > div{
    margin-bottom: 5%;
}
.logo_title {
    /* color: #FFF; */
    color: #000000;
    margin-top: 5%;
    margin-bottom: 15%;
}
input{
    padding-left: 30px;
}
input::placeholder{
    color: #bbb9b9;
} 

a{
    color: #333;
}
a:link{
    color: #333;
}
a:active{
    color: #333;
}
a:hover{
    color:#333;
}
.foot{
    position: relative;
    bottom: -10px;
    height: 40px;
    line-height: 40px;
    background: #bbb9b944;
}
</style>