var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{attrs:{"height":"100%"}},[_c('el-main',[_c('div',{ref:"div_scroll",staticStyle:{"overflow-y":"auto","height":"100%"}},[(_vm.typ=='share')?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-row',{staticClass:"row-h row-h-first"},[_c('el-col',{attrs:{"span":2}}),_c('el-col',{staticStyle:{"text-align":"right","padding-right":"10px"},attrs:{"span":20}},[_c('el-input',{attrs:{"type":"text","disabled":""},model:{value:(_vm.shareLink),callback:function ($$v) {_vm.shareLink=$$v},expression:"shareLink"}},[_c('div',{attrs:{"slot":"append"},slot:"append"},[_c('el-button',{attrs:{"icon":"el-icon-copy-document"},on:{"click":_vm.copyLink}},[_vm._v("复制链接")])],1)])],1),_c('el-col',{attrs:{"span":2}})],1)],1):_vm._e(),(_vm.typ=='page')?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-row',{staticClass:"row-h row-h-first"},[_c('el-col',{attrs:{"span":2}}),_c('el-col',{staticStyle:{"text-align":"right","padding-right":"10px"},attrs:{"span":20}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('h2',[_vm._v("来自 "+_vm._s(_vm.you)+" 的对话分享")]),_c('hr')])]),_c('el-col',{attrs:{"span":2}})],1)],1):_vm._e(),_vm._l((_vm.chatList),function(chatone,index){return _c('div',{key:chatone.id},[_c('el-row',{class:{  
                        'row-h': true,  
                        'row-h-first': index === 0,  
                    }},[_c('el-col',{staticStyle:{"text-align":"right","padding-right":"10px"},attrs:{"span":2}},[(chatone.role == 'user')?_c('el-button',{attrs:{"type":"warning","icon":"el-icon-s-custom","circle":""}}):_c('el-button',{attrs:{"type":"primary","icon":"el-icon-user-solid","circle":""}})],1),_c('el-col',{staticStyle:{"text-align":"left","padding-left":"10px"},attrs:{"span":20}},[_c('el-row',[_c('el-col',{staticClass:"el-col-title",attrs:{"span":24}},[_vm._v(_vm._s(chatone.role == 'user'?_vm.you:'启鹏Ai'))]),_c('el-col',{class:{
                                'col-content':true,
                                'aiContent':chatone.role == 'user'
                            },attrs:{"span":24}},[_c('vue-markdown',{attrs:{"source":chatone.content,"breaks":true}})],1)],1)],1),_c('el-col',{attrs:{"span":2}})],1)],1)}),_c('div',[(_vm.messCont!='')?_c('el-row',{staticClass:"row-h"},[_c('el-col',{staticStyle:{"text-align":"right","padding-right":"10px"},attrs:{"span":2}},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-user-solid","circle":""}})],1),_c('el-col',{staticStyle:{"text-align":"left","padding-left":"10px"},attrs:{"span":20}},[_c('el-row',[_c('el-col',{staticClass:"el-col-title",attrs:{"span":24}},[_vm._v("启鹏Ai")]),_c('el-col',{staticClass:"col-content",attrs:{"span":24}},[_c('vue-markdown',{attrs:{"source":_vm.messCont,"breaks":true}})],1)],1)],1),_c('el-col',{attrs:{"span":2}})],1):_vm._e()],1)],2)]),(_vm.typ=='page')?_c('el-footer',{attrs:{"height":"50px"}},[_c('el-row',[_c('el-col',{attrs:{"span":2}}),_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":22}},[_c('el-button',{attrs:{"type":"primary","icon":_vm.iconClass,"disabled":_vm.isDisabledButton},on:{"click":_vm.toQiPengAi}},[_vm._v("前往启鹏Ai")])],1),_c('el-col',{attrs:{"span":2}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }