<template>
    <div>
        <el-row width="100%">
            <el-col :span="5" v-for="o in packList" :key="o.id" :offset="1">
                <el-card :body-style="{ padding: '0px'}" >
                    <div style="padding: 10px;" @click="cardSelect(o.id,o.amountYH)" :class="{
                        'el-card-selected' : o.id == TcId
                    }">
                        <h3>{{o.tcName}}</h3>
                        <div class="conte">
                            <p><label class="lab">{{ o.number==0?'无限':o.number }} </label> 次 | <label class="lab">{{ o.endDays }}</label> 天</p>
                            <p style="color: #ffa500;">￥<label class="lab">{{ o.amountYH }} </label>元</p>
                            <p><label style="text-decoration: line-through;">￥{{ o.amount }}</label></p>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-if="TcId>0" style="margin-top: 20px;">
            <el-col :span="5" style="text-align: left; position: relative;" :offset="1">
                <canvas ref="qrCanvas"></canvas>
                <div class="guoqi-zhedang" v-if="orderSch_T>300">
                    <div class="guoqi">已过期</div>
                </div>
            </el-col>
            <el-col :span="5" style="text-align: left;" :offset="1">
                <div>
                    <div style="margin-top: 50px;">
                        金额：￥<label class="lab">{{selectTcMoney}}</label>元
                    </div>
                    <div style="margin-top: 30px;">使用`<b style="font-size: larger;">微信</b>`扫码支付</div>
                </div>
            </el-col>
            
        </el-row>
    </div>
</template>

<style>

  .button {
    padding: 0;
    float: right;
  }
  .guoqi-zhedang{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #1111113b
  }
  .guoqi{
    position: absolute;
    display: block;
    padding: 5px;
    color: #333;
    background: #FFF;
    border-radius: 4px;
    top: 40%;
    left: 31%;
  }

  .conte p{
    margin-bottom: 5px;
  }
  .conte{
    margin-top: 15px;
  }
  .lab{
    color: #ffa500;
    font-size: 16px;
    font-weight: 700;
    
  }
  .el-card{
    cursor: pointer;
  }
  .el-card-selected{
    border: #ffa500 1px solid;
    border-radius: 4px;
    box-shadow: inset 0 0 6px (255, 165, 0, 0.5); /* 阴影效果 */ 
    -webkit-box-shadow: inset 0 0 6px (255, 165, 0, 0.5); /* 阴影效果 */ 
  }

</style>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'
import QRCode from 'qrcode'

    export default {
        name: 'taoCanPG',
        props: {  
            selectTcId: {  
                type: Number, // 或者其他你需要的类型  
                default: 0 // 默认值  
            }  
            // ... 其他 props  
        },  
        data() {
            return {
                currentDate: new Date(),
                packList:{},
                TcId:0,
                selectTcMoney:0,
                tradeNo:'',
                orderSch:null,
                orderSch_T:0
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
                // console.log("=========dddddddddddddddd=====")
            this.orderSch = clearInterval(this.orderSch);
        },
        watch: {  
            selectTcId(newVal) {  
                if (newVal) {  
                    // 当对话框打开时执行的代码  
                    this.init();
                }  
            }  
        },
        methods:{
            init:function(){
                this.initList();
                this.TcId = this.selectTcId;
                this.selectTcMoney=0;
                // console.log("^^^^^^^^^^^^^^^^^^^    "+this.selectTcId);
            },
            initList:async function(){
                try {
                    const loginUrl=$conf.dev.apiUrl+'pack/list';
                    const response = await axios.get(loginUrl, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$data.packList=resD.data;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            cardSelect: async function(inx,money){ 
  
                const token = cookieH.get("userTK");
                
                if(token == "" || token == undefined){
                    this.$message.error("您还没有登陆，请先登录");
                    this.isDisabledButton = false;
                    this.$router.replace('/');
                    return;
                }

                this.TcId = inx; 
                this.selectTcMoney=money;


                try {
                    const loginUrl=$conf.dev.apiUrl+'pack/wechatUrl';
                    const response = await axios.post(loginUrl,{
                        token:token,
                        tcid:inx
                    }
                    , {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.tradeNo = resD.msg;
                        this.$nextTick(()=>{
                            // 确保 canvas 存在  
                            if (!this.$refs.qrCanvas) {  
                                console.error('Canvas not found!');  
                                return;  
                            }  
                            const text = resD.data;  
                            QRCode.toCanvas(this.$refs.qrCanvas, text, error => {  
                                if (error) {  
                                    console.error('Error generating QR code:', error);  
                                    this.$message.error("生成二维码时出错，请稍后再试。");  
                                    return;  
                                }  

                                // 在这里显示消息，因为二维码已经成功生成  
                                // this.$message.info("功能完善中，请耐心等待……" + inx); 
                                this.orderSch_T=0;
                                this.orderSch = clearInterval(this.orderSch);
                                this.orderSch = setInterval(this.queryOrder,5000) 
                            });
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            queryOrder:async function(){
                try {
                    this.orderSch_T+=5;
                    const loginUrl=$conf.dev.apiUrl+'pack/order/query';
                    const response = await axios.post(loginUrl, {
                        tradeNo:this.tradeNo
                    }
                    ,{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        const orderInfo =resD.data;
                        if(orderInfo.tradeStatus==1){
                            this.$message.success("支付成功");
                            this.orderSch = clearInterval(this.orderSch);
                            this.closeDialog();
                        }
                        else if(orderInfo.tradeStatus==3){
                            this.$message.info("支付成功，但是后台处理数据失败，联系管理员");
                            this.orderSch = clearInterval(this.orderSch);
                        }
                        else if(orderInfo.tradeStatus==2){
                            this.$message.error("支付失败，请稍后在试");
                            this.orderSch = clearInterval(this.orderSch);
                        }else{
                            if(this.orderSch_T>300){
                                this.$message.error("订单长时间未支付，已失效");
                                this.orderSch = clearInterval(this.orderSch);
                            }
                        }
                    }else{
                        this.$message.error(resD.msg);  
                        this.orderSch = clearInterval(this.orderSch);
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            closeDialog() {  
                // 触发自定义事件 'close-dialog' 
                // console.log("ddddddddddddddddddddddddddddddd=====")
                this.orderSch = clearInterval(this.orderSch);
                this.$emit('close-dialog');  
            }

        }
    }
</script>