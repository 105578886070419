<template>
    <el-container>
        <el-drawer
            title="历史对话"
            :visible.sync="drawer"
            direction="rtl"
            size="80%">
            <!-- <el-menu class="el-menu-demo">
                <el-menu-item index="0" :key="topicId">
                    <div class="icon-top" @click="chatPI(0)" :data-index="0">
                        新建会话
                        <div class="icon-menu right-0"><i class="el-icon-circle-plus"></i></div>
                    </div>
                </el-menu-item>
            </el-menu> -->
            <el-menu class="el-menu-demo text-left">
                <el-menu-item :class="{
                    'ellipsis':true,
                    'div-selectd':topi.id==topic.tid
                }" v-for="topi in topicList" :key="topi.id">
                <el-row v-show="!isEidt[topi.id]">
                    <el-col :span="24">
                    <div class="icon-top" @mouseenter="showPI" @mouseleave="hidePI" @click="chatPI(topi.id)" :data-ref="'top_'+topi.id" :data-index="topi.id">
                        {{ topi.topic }}
                        <div  class="icon-menu right-20" v-show="isShow[topi.id]" :data-index="topi.id" :data-visible="isShow[topi.id]==true?1:0">
                            <i class="el-icon-edit" @click="editPI(topi.id)"></i>
                            <i class="el-icon-share" @click="sharePI(topi.id)"></i>
                            <i class="el-icon-delete" @click="deletePI(topi.id)"></i>
                        </div>
                    </div>
                </el-col>
                </el-row>
                
                <el-row v-show="isEidt[topi.id]">
                            <el-col :span="24">
                                <el-input 
                                    type="text" 
                                    :placeholder="topi.topic"
                                    v-model="topicTitle"
                                    style="padding-right: 10px; width:102%;">
                                    <div slot="suffix">
                                        <i class="el-icon-check" @click="sureEdit(topi.id)" style="cursor: pointer;"></i>
                                        <i class="el-icon-close" @click="escEdit(topi.id)" style="cursor: pointer;"></i>
                                    </div>
                                    </el-input></el-col>
                        </el-row>
                </el-menu-item>
            </el-menu>
        </el-drawer>
        <!-- <el-container height="100%"> -->
            <el-header>
                <el-row>
                    <el-col :span="19" style="text-align: left;" >有效期
                        <el-tooltip class="item" effect="dark" content="有效期内可不限次使用" placement="bottom">
                            <i class="el-icon-question"></i>
                        </el-tooltip>：{{formattedDate(uInfo.expressTime)}}</el-col>
                    <el-col :span="5">
                        <el-menu class="el-menu-demo" mode="horizontal" style="float: right;">
                            <el-submenu index="002">
                                <template slot="title" ><i class="el-icon-user-solid" style="color: #333;"></i><span style="color: #333;">{{uInfo.nickName==undefined?uInfo.userName:uInfo.nickName}}</span></template>
                                <el-menu-item index="002-002" style="color: orange;" @click="chongzhi"><i class="el-icon-present" style="color: orange;"></i> 充值</el-menu-item>
                                <el-menu-item index="002-004" @click="chatPI(0)" style="color: #409EFF;"><i class="el-icon-circle-plus-outline" style="color: #409EFF;"></i> 新建对话</el-menu-item>
                                <el-menu-item index="002-003" @click="history"><i class="el-icon-s-operation"></i> 历史对话</el-menu-item>
                                <el-menu-item index="002-001" @click="toExit"><i class="el-icon-switch-button"></i> 退出</el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </el-col>
                </el-row>
            </el-header>
            <el-main><onceChat :tid="topic.tid" :lid="topic.lid" /></el-main>
            <!-- <el-main><iframe style="height: 100%; width: 100%; overflow: hidden;" src="#/once" scrolling="none" frameborder="0" id="FrmContent" ></iframe></el-main> -->
            <el-footer height="30px">
                内容由AI生成，无法确保真实准确，仅供参考;版本:V1.0.0
            </el-footer>
            <el-footer height="30px">
                请遵守<span style="text-decoration: underline; cursor: pointer;" @click="openpMsg">《启鹏Ai用户协议》</span>、<span style="text-decoration: underline; cursor: pointer;" @click="openpYSMsg">《启鹏Ai个人信息保护规则》</span>
            </el-footer>
            <!-- Dialog组件 -->
            <el-dialog :visible.sync="dialogVisible" title="启鹏Ai用户协议">
                <!-- 引入Vue文件 -->
                <pmessVue />
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">关闭</el-button>
                </span>
            </el-dialog>
            
            <!-- Dialog组件 -->
            <el-dialog :visible.sync="dialogVisibleB" title="启鹏Ai个人信息保护规则">
                <!-- 引入Vue文件 -->
                <pYSmessVue />
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleB = false">关闭</el-button>
                </span>
            </el-dialog>
            
            <!-- Dialog组件 -->
            <el-drawer class="my-custom-dialog" :visible.sync="dialogtaoCanPG" title="启鹏Ai会员充值" size="70%" direction="btt" >
                <!-- 引入Vue文件 -->
                <!-- <taoCanPGVue :selectTcId="0" />   使用v-if来控制taoCanPGVue的创建和销毁   -->
                <mobileTaoCanPG v-if="dialogtaoCanPG" :selectTcId="0" ref='taoCanPGRef' @close-dialog="handdleCloseTaoCan" />  
                <!-- <span slot="footer" class="dialog-footer">  
                    <el-button @click="dialogtaoCanPG = false" style="float: left;">关闭</el-button>  
                    <img src="../../assets/alipay.png" height="39px"/>
                    <el-button @click="dialogtaoCanPG = false" type="primary">去支付</el-button>  
                </span> -->
            </el-drawer>
            <!-- Dialog组件 -->
            <el-dialog :visible.sync="dialogSharePG" title="分享页面">
                <!-- 引入Vue文件 -->  <!-- 使用v-if来控制taoCanPGVue的创建和销毁   -->
                <chatShareVue v-if="dialogSharePG" :vMarkG="share.vMarkG" :typ="share.typ" @close-dialog="dialogSharePG = false" />  
                <span slot="footer" class="dialog-footer">  
                    <el-button @click="dialogSharePG = false">关闭</el-button>  
                </span>
            </el-dialog>
        <!-- </el-container> -->
    </el-container>
</template>

<script>
import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'
import onceChat from './onceChat.vue'
import pmessVue from '../message/pMess.vue'
import pYSmessVue from '../message/pYSMess.vue'
import mobileTaoCanPG from '../pack/mobileTaoCanPG.vue'
import chatShareVue from './chatShare.vue'

export default {
name: 'mobileMain',
components: {
    onceChat,
    pmessVue,
    pYSmessVue,
    mobileTaoCanPG,
    chatShareVue
},
props:{
        topicId: {
            type: [String, Number],
            default: 0,
        },
    },
data()
{
    return {
        LGOIN_CODE: 0,  
        isShow:{0:false},
        isEidt:{48:false},
        topicList:{},
        uInfo:{},
        topic:{
            tid:0,
            lid:0,
        },
        share:{
            vMarkG:"",
            typ:"share"
        },
        dialogVisible:false,
        dialogVisibleB:false,
        dialogtaoCanPG:false,
        dialogSharePG:false,
        topicTitle:'',
        drawer:false
    };
},
mounted(){
    this.init();
    // console.log(this.topicId)
},
methods:{
    showPI:function(event){
        const inx = event.target.dataset.index;
        this.$set(this.isShow, inx, true);
    },
    hidePI:function(event){
        const inx = event.target.dataset.index;
        this.$set(this.isShow, inx, false);
    },
    chatPI:function(inx){
        if(inx!=this.topic.tid){
            this.drawer=false;
            this.$router.push('/mobileChat/'+inx)
        }
    },
    formattedDate(times) {  
        const date = new Date(times * 1000); // 乘以 1000 转换为毫秒  
        return date.toLocaleString(); // 使用默认的本地化格式  
    },
    editPI:function(inx){
        this.$set(this.isEidt, inx, true);
    },
    async sureEdit(inx){
        if(this.topicTitle==''){
            this.$message.error("主题标题不能为空");
            return false;
        }
        const token = cookieH.get("userTK");
        if(token == "" || token == undefined){
            this.$message.error("您还没有登陆，请先登录");
            this.$router.push('/');
        }else{
            try {
                const loginUrl=$conf.dev.apiUrl+'chat/topic/update';
                const response = await axios.post(loginUrl,{
                    id: inx,
                    topic:this.topicTitle
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    this.topicList.forEach(topic => {
                        if(topic.id==inx){
                            topic.topic=this.topicTitle;
                        }
                    });
                    this.topicTitle=''
                    this.$set(this.isEidt, inx, false);
                    this.$message.success("修改成功！");
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            }  
        }
    },
    async escEdit(inx){
        this.topicTitle=''
        this.$set(this.isEidt, inx, false);
    },
    async sharePI(inx){

        const token = cookieH.get("userTK");
        if(token == "" || token == undefined){
            this.$message.error("您还没有登陆，请先登录");
            this.$router.push('/');
        }else{
            try {
                const loginUrl=$conf.dev.apiUrl+'chat/share';
                const response = await axios.post(loginUrl,{
                    token:token,
                    tid: inx
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    this.share.vMarkG = resD.data.VMarkG;
                    console.log(resD.data.VMarkG +"     ||     "+this.share.vMarkG)
                    this.dialogSharePG=true;
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            }  
        }

            // this.$message.info("功能完善中，请耐心等待……"+inx);
    },
    async deletePI(inx){
        const token = cookieH.get("userTK");
        if(token == "" || token == undefined){
            this.$message.error("您还没有登陆，请先登录");
            this.$router.push('/');
        }else{
            this.$confirm('此操作将永久删除该主题, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    const loginUrl=$conf.dev.apiUrl+'chat/topic/delete';
                    const response = await axios.post(loginUrl,{
                        id: inx
                    }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success("删除成功！");
                        this.topicList = this.topicList.filter(topic => topic.id !== inx);
                        if(this.topic.tid==inx){
                            this.$router.push('/mobileChat/0')
                        }
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                }  
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
            
        }
    },
    chongzhi:function(){
        this.dialogtaoCanPG=true;
    },
    init:function(){
        this.isShow={0:false}
        this.initUser();
        setInterval(this.initUser,60000);
        this.initTopic();
        this.topic.tid=this.topicId;
    },
    toExit:function(){
        cookieH.remove("userTK");
        this.$router.replace('/');
    },
    history:function(){
        // this.$refs.aside
        this.drawer=true;
    },
    initTopic:async function(){
        const token = cookieH.get("userTK");
        if(token == "" || token == undefined){
            this.$message.error("您还没有登陆，请先登录");
            this.$router.push('/');
        }else{
            try {
                const loginUrl=$conf.dev.apiUrl+'chat/topic';
                const response = await axios.post(loginUrl,{
                    token: token,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    this.topicList=resD.data;
                    // 在这里循环 topicList 并向 isShow 添加属性  
                    // this.topicList.forEach(topic => {  
                    //     this.$set(this.isShow, topic.id, false); // 使用 Vue.set 或 this.$set 确保响应性  
                    // }); 
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            }  
        }
    },
    initUser:async function(){
        const token = cookieH.get("userTK");
        if(token == "" || token == undefined){
            this.$message.error("您还没有登陆，请先登录");
            this.$router.push('/');
        }else{
            try {
                const loginUrl=$conf.dev.apiUrl+'index/uinfo';
                const response = await axios.post(loginUrl,{
                    token: token,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    this.uInfo=resD.data;
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            }  
        }
    },
    openpMsg:function(){
        this.dialogVisible=true

    },
    openpYSMsg:function(){
        this.dialogVisibleB=true

    },
    handdleCloseTaoCan(){
        if (this.$refs.taoCanPGRef) {  
        this.$refs.taoCanPGRef.closeDialog(); // 调用子组件的 close 方法  
        }  
        this.dialogtaoCanPG=false;
    }

}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-container{
height: 100%;
overflow: hidden;
}
.el-input-append{
padding: 0;
background-color: none;
border:0;
position: absolute;
top: 10px;
right:0;
}
::v-deep.el-input--suffix .el-input__inner{
padding-right: 55px;
}
.el-footer{
    padding: 0;
}
.el-card__body, .el-main{
    padding: 5px;
}
.right-20{
right: -20px;
}
.right-0{
right: 0;
}
.icon-menu{
float: right;
line-height: 56px;
position: absolute;
top: 0;
border-bottom-left-radius: 28px;
border-top-left-radius: 28px;
background: rgb(255, 255, 255);
}
.el-menu-item[class^=el-icon-]:hover{
background: #f4f4f4;
padding: 4px 2px 4px 2px;
border-radius: 5px;
}
.text-left{
text-align: left;
background:none;
}
.div-selectd{
background-color: #ebeef5;
}
.icon-top{
    height:100%;
}
.el-menu-item.is-active{
    color: #333;
}
.ellipsis {  
/* 设置文本在一行内显示 */  
white-space: nowrap;  
/* 隐藏超出容器的文本 */  
overflow: hidden;  
/* 使用省略号表示超出部分 */  
text-overflow: ellipsis;  
}
.el-header{
    background-color: #ffffff;
    color: #333;
    line-height: 60px;
    text-align: right;
    border-bottom: 1px solid #f4f4f4;
}
el-col:first{
    text-align: left;
}
.el-footer {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #f4f4f4;
}

.el-aside {
    background-color: #fafafa;
    color: #333;
    text-align: center;
    width:20vh;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
}

.el-main {
color: #333;
text-align: center;
}
::v-deep .el-dialog{
    width: 100%;
}
::v-deep .my-custom-dialog .el-dialog__wrapper {  
  width: 100%;  
}  

/* 如果还是不起作用，可以尝试使用 !important 来增加优先级 */  
::v-deep .my-custom-dialog .el-dialog__wrapper {  
  width: 100% !important;  
}
</style>