<template>
    <div>
        
        <el-row>
            <el-col :span="24">
               <div class="title">模板分类</div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-row>
                    <el-col :span="20">
                        <div class="query">
                            <div class="content">
                                分类名称：
                                <el-input
                                    type="text"
                                    placeholder="分类名称"
                                    v-model="query.libName"
                                    style="width: 150px !important;">
                                </el-input>
                                <el-button type="success" @click="initLibList(1)">查询</el-button>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <div style="margin-bottom: 20px; text-align: right;">
                            <el-button type="primary" @click="handleClick_Add(0)">添加分类</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-table
                    :data="tempLibList.data"
                    style="width: 100%"
                    row-key="id"
                    border
                    lazy
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column
                        prop="id"
                        label="序号"
                        width="180"
                        hidden>
                    </el-table-column>
                    <el-table-column
                        prop="libname"
                        label="名称"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="level"
                        label="层级">
                    </el-table-column>
                    <el-table-column
                        prop="parentid"
                        label="parentId"
                        hidden>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        align="right"
                        label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" v-if="scope.row.level!=3" size="small" @click="handleClick_Add(scope.row)">添加子分类</el-button>
                            <el-button type="success" size="small" @click="handleClick_Edit(scope.row)">编辑</el-button>
                            <el-button type="danger" size="small" @click="handleClick_Delete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="tempLibList.count"
                    :page-size="pageSize"
                    @current-change="initLibList">
                </el-pagination>
            </el-col>
        </el-row>

        <!-- Dialog组件 -->
        <el-drawer class="my-custom-dialog" :visible.sync="dialogAdd_Edit" :title="form.title" size="50%" direction="rtl" @close="closeDialog">
            <!-- 引入Vue文件 -->  
            <el-row>
                <el-col :span="5">&nbsp;</el-col>
                <el-col :span="14">
                    <el-card>
                        <el-form ref="targetForm" :model="form" label-width="80px">
                            <el-form-item label="分类名称">
                                <el-input v-model="form.libName"></el-input>
                                <el-input v-model="form.id" v-if="false"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit">保存</el-button>
                                <el-button @click="closeDialog">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-col>
                <el-col :span="5"></el-col>
            </el-row>
        </el-drawer>
    </div>
</template>

<style>

</style>

<script>
// import cookieH from 'js-cookie'
import axios from 'axios'
import $conf from '@/apiconfig'

    export default {
        name: 'tempLib',
        data() {
            return {
                tempLibList:{},
                pageSize:10,
                query:{
                    libName:''
                },
                dialogAdd_Edit:false,
                form:{
                    title:'',
                    id:0,
                    libName:'',
                    parentId:0,
                    parentName:'',
                    level:1
                }
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
                this.initLibList(1);
            },
            async initLibList(pageIndex){
                try {
                    pageIndex = (pageIndex==undefined || pageIndex == 0) ? 1 : pageIndex
                    const loginUrl=$conf.dev.apiUrl+'temp/lib/list';
                    const response = await axios.post(loginUrl, {
                        libName:this.query.libName,
                        pageSize:this.pageSize,
                        pageIndex:pageIndex
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.tempLibList=resD;
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            handleClick_Edit(row){
                this.dialogAdd_Edit=true
                this.form.title="编辑模板分类"
                this.form.id=row.id;
                this.form.libName=row.libname;
                this.form.parentId=row.parentid;
                this.form.level=row.level;
                console.log(row);
            },
            closeDialog(){
                this.dialogAdd_Edit=false
                this.form.title=""
                this.form.id=0;
                this.form.libName='';
                this.form.parentId=0;
                this.form.level=1;
            },
            handleClick_Add(row){
                this.dialogAdd_Edit=true
                this.form.title="添加模板分类"
                this.form.id=0;
                this.form.libName='';
                
                if(row!=0){
                    this.form.parentId=row.id;
                    this.form.level=row.level+1;
                }else{
                    this.form.parentId=0;
                    this.form.level=1;
                }
            },
            async handleClick_Delete(row){
                try {
                    const vm = this
                    this.$confirm('此操作将永久删除该分类及其所有下级分类, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(async () =>  {
                        const loginUrl=$conf.dev.apiUrl+'temp/lib/delete';
                        const response = await axios.post(loginUrl, {
                            id:row.id,
                            level:row.level
                        },{
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });  
                        const resD = response.data;
                        if(resD.code==0){
                            this.$message.success({
                                message:resD.msg,
                                duration:1000,
                                onClose:function(){
                                    vm.initLibList(1)
                                    vm.closeDialog()
                                }
                            })
                        }else{
                            this.$message.error(resD.msg);  
                        }
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
                    });
                    
                } catch (error) {  
                    this.$message.error(error);  
                } 
            },
            async onSubmit(){
                try {
                    const vm = this
                    const loginUrl=$conf.dev.apiUrl+'temp/lib/save';
                    const response = await axios.post(loginUrl, {
                        id:this.form.id,
                        libName:this.form.libName,
                        parentId:this.form.parentId,
                        level:this.form.level
                    },{
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });  
                    const resD = response.data;
                    if(resD.code==0){
                        this.$message.success({
                            message:resD.msg,
                            duration:1000,
                            onClose:function(){
                                vm.initLibList(1)
                                vm.closeDialog()
                            }
                        })
                    }else{
                        this.$message.error(resD.msg);  
                    }
                } catch (error) {  
                    this.$message.error(error);  
                } 
            }
        }
    }
</script>