<template>
    <div>
        工作台
    </div>
</template>

<style>

</style>

<script>
// import cookieH from 'js-cookie'
// import axios from 'axios'
// import $conf from '@/apiconfig'

    export default {
        name: 'etCard',
        data() {
            return {
            };
        },
        mounted(){
            this.init();
        },
        beforeDestroy(){
        },
        methods:{
            init:function(){
            },

        }
    }
</script>