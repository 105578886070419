<template>
    <div class="logo">
        <img alt="启鹏Ai logo" src="../../assets/logo.png">
        <div class="layui-col-sm12 layui-col-md12">
            <fieldset class="logo_title layui-elem-field layui-field-title" style="margin-top: 20px; margin-bottom: 20px;">
                <legend> 登录 </legend>
            </fieldset>
        </div>
        <div class="login">
            <div class="layui-row layui-col-space15">
                    <div class="layui-col-sm12 layui-col-md12">
                        <div class="layui-form-item">
                            <input type="text" name="phone" v-model="phone" autocomplete="off" placeholder="手机号码" class="layui-input">
                            <i class="layui-icon layui-icon-username zyl_lofo_icon"></i>
                        </div>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <div class="layui-row">
                            <div class="layui-col-xs6 layui-col-sm6 layui-col-md6">
                                <div class="layui-form-item">
                                    <input type="text" name="vercode" v-model="code" id="vercode" autocomplete="off" placeholder="验证码" class="layui-input" maxlength="6">
                                    <i class="layui-icon layui-icon-vercode zyl_lofo_icon"></i>
                                </div>
                            </div>
                            <div class="layui-col-xs2 layui-col-sm2 layui-col-md2">&nbsp;</div>
                            <div class="layui-col-xs4 layui-col-sm4 layui-col-md4 demoTable">
                                <button id="getcode" @click="getcode" class="layui-btn layui-bg-gray" ref="getcode">获取验证码</button>
                            </div>
                        </div>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <button class="layui-btn layui-bg-blue layui-btn-fluid"  @click="toLogin">立即登录</button>
                    </div>
                    <div class="layui-col-sm12 layui-col-md12">
                        <div style="height: 30px; line-height: 30px; margin-bottom: 20px;">还没有启鹏Ai帐号？<el-link type="primary" @click="toRegister">立即注册</el-link></div>
                    </div>
            </div>
        </div>
        <div class="foot">
            Copyright 2024 ©启鹏供应链 版权所有 备案号：<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><u>粤ICP备2024240429号-1</u></a>
        </div> 
    </div>
</template>
  
  <script>
    import cookieH from 'js-cookie'
    import axios from 'axios'
    import $conf from '@/apiconfig'
    // import $router from 'vue-router'


  export default {
    name: 'HelloWorld',
    data()
    {
        return {
            LGOIN_CODE: 0,  
            BIZID: "",  
            vodeA: undefined,
            phone:"",
            code:"",
        };
    },
    created(){
        
        // console.log("jiancha000")
        this.init();
    },
    methods:{
        jishi:function(){
            if(this.$data.LGOIN_CODE<=0){
                this.$data.BIZID="";
                cookieH.remove("LOGIN_CODE");
                // cookieH.remove("LOGIN_BIZID");
                this.$refs.getcode.innerText="获取验证码";
                this.$refs.getcode.removeAttribute("disabled");
                if(this.$data.vodeA!=undefined){
                    clearInterval(this.$data.vodeA);
                    this.$data.vodeA=undefined;
                }
            }else{
                this.$data.LGOIN_CODE--;
                cookieH.set("LOGIN_CODE",this.$data.LGOIN_CODE,{expires:7});
                this.$refs.getcode.innerText = this.$data.LGOIN_CODE+" s";
                this.$refs.getcode.setAttribute('disabled','disabled');
            }
        },
        checkLogin(){
            // console.log("checkLogin")
            const token = cookieH.get("userTK");
            // console.log("checkLogin - token",token)
            if(token != "" && token != undefined ){
                if(this.getDeviceType()=="mobile"){
                    this.$router.replace('/mobileChat/0');
                }else{
                    this.$router.replace('/chatMain/0/0');
                }
                return false;
            }
            return true;
        },
        init:function(){
// console.log("jiancha")
           const ab=  this.checkLogin();
            
            var codeCookie = cookieH.get("LOGIN_CODE");
            if (codeCookie == 0 || codeCookie == undefined) {
                this.$data.LGOIN_CODE = 0;

            }else{
                this.$data.LGOIN_CODE = codeCookie;
            }
            if(this.$data.vodeA==undefined && ab){
                this.$data.vodeA = setInterval(this.jishi,1000)
            }

        },
        getcode: async function () {
            try {  
                this.validateCode();
                const loginUrl=$conf.dev.apiUrl+'index/sms';
                const response = await axios.post(loginUrl,{
                    phone: this.$data.phone,
                    type: 1
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    this.$message.info(resD.msg);  
                    cookieH.set("LOGIN_CODE",60,{expires:1});
                    cookieH.set("LOGIN_BIZID",resD.data,{expires:1});
                    this.$data.BIZID=resD.data;
                    this.init();
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            } 

            // cookieH.set("LOGIN_CODE",60,{expires:1});
            // cookieH.set("LOGIN_BIZID","465715714027254894^0",{expires:1});
            // this.$data.BIZID="465715714027254894^0";
            // this.init();
        },
        toLogin: async function(){
            try {  
                this.validate();
                const loginUrl=$conf.dev.apiUrl+'index/login';
                const response = await axios.post(loginUrl,{
                    phone: this.$data.phone,
                    code: this.$data.code,
                    bizid:this.$data.BIZID
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });  
                const resD = response.data;
                if(resD.code==0){
                    cookieH.set("userTK",resD.data,{expires:7});
                    cookieH.remove("LOGIN_CODE");
                    cookieH.remove("LOGIN_BIZID");
                    clearInterval(this.$data.vodeA);
                    if(this.getDeviceType()=="mobile"){
                        this.$router.replace('/mobileChat/0');
                    }else{
                        this.$router.replace('/chatMain/0/0');
                    }
                }else{
                    this.$message.error(resD.msg);  
                }
            } catch (error) {  
                this.$message.error(error);  
            }  
        },
        toRegister:function(){
            this.$router.push('/veReg');
        },
        validateCode:function(){
            if(this.phone.trim() == ""){
                this.$message.error("手机号码必填");
                return false;
            }
            const phontRe = /^1[3-9]\d{9}$/;
            if(!phontRe.test(this.phone)){
                this.$message.error("手机号码格式错误");
                return false;
            }
        },
        validate:function(){
            if(this.$data.phone == ""){
                this.$message.error("手机号码必填");
                return false;
            }
            const phontRe = /^1[3-9]\d{9}$/;
            if(!phontRe.test(this.$data.phone)){
                this.$message.error("手机号码格式错误");
                return false;
            }
            if(this.$data.code == ""){
                this.$message.error("验证码必填");
                return false;
            }
        },getDeviceType() {  
            let isMobile = false;
            // 首先检查navigator.maxTouchPoints，这是一个更可靠的方法  
            if ('maxTouchPoints' in navigator) {  
                isMobile = navigator.maxTouchPoints > 0?true:false;
                return isMobile ? 'mobile' : 'desktop';  
            }  
            else{
                
                // 常见的移动设备用户代理片段  
                const mobileUserAgentPatterns = [  
                    /Android/i,  
                    /webOS/i,  
                    /iPhone|iPad|iPod/i,  
                    /BlackBerry/i,  
                    /Windows Phone/i,  
                    /Opera Mini/i,  
                    /IEMobile/i,  
                    /Mobile/i, // 通用移动标识  
                    /Mobile Safari/i,  
                    /Opera Mobi/i,  
                    /Tablet/i, // 平板电脑通常也作为移动设备处理  
                    /Mobile MSIE/i,  
                    /Windows CE/i,  
                    /UCWEB/i,  
                    /UC Browser/i,  
                    /MiuiBrowser/i,  
                    /Mobile Safari/i,  
                    /Chrome Mobile/i,  
                    /Firefox Mobile/i  
                ];  
                
                // 获取用户代理字符串  
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
                
                // 检查用户代理是否包含上述任何移动设备标识  
                for (let i = 0; i < mobileUserAgentPatterns.length; i++) {  
                    if (userAgent.match(mobileUserAgentPatterns[i])) {  
                    return 'mobile'; // 是移动设备  
                    }  
                }  
                return 'desktop';
            }
           
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .logo{
    text-align: center;
  }
  .logo img{
    width: 200px;
    margin-top: 40px;
  }
  .login {
    width: 350px; /* 设置固定宽度 */  
    height: 250px; /* 设置固定高度 */  
    border: 1px solid #ccc; /* 设置边框 */  
    border-radius: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 设置阴影 */  
    display: flex; /* 如果需要内部内容垂直居中 */  
    align-items: center; /* 内部内容垂直居中 */  
    justify-content: center; /* 内部内容水平居中 */  
    /* 如果需要水平居中，可以添加 margin: auto; 但这通常与 flex 布局一起使用 */  
    margin: auto; /* 当 login 是其容器的直接子元素且其父容器为 flex 容器时有效 */  
    text-align: center; /* 如果 login 内只有文本，确保文本居中 */ 
    margin-top: 50px; 
    padding: 2%;
  }


  .zyl_lofo_icon{
        position: absolute;
        left: 5px; 
        top: 8px; 
        font-size: 20px;
        /* color:#FFF; */
        color: #000000;
    }
    .layui-form > div{
        margin-bottom: 5%;
    }
    .logo_title {
        /* color: #FFF; */
        color: #000000;
        margin-top: 5%;
        margin-bottom: 15%;
    }
    input{
        padding-left: 30px;
    }
    input::placeholder{
        color: #bbb9b9;
    } 

    a{
        color: #333;
    }
    a:link{
        color: #333;
    }
    a:active{
        color: #333;
    }
    a:hover{
        color:#333;
    }
    .foot{
        position: relative;
        bottom: 0px;
        height: 40px;
        line-height: 40px;
    }
  </style>